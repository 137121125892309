// @flow

/**
 * SideBarNav Component
 */

import styled from 'styled-components';

import Header from './Header';
import Link from './Link';
import Links from './Links';

const SideBarNav = styled.nav`
  border-right: #cfcfcf 1px solid;
  display: block;
  flex: 1;
  padding-right: 2em;
  padding-top: 72px;
`;

SideBarNav.Header = Header;
SideBarNav.Link = Link;
SideBarNav.Links = Links;

export default SideBarNav;
export { Header, Link, Links };
