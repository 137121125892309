import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'react-router-redux';
import React from 'react';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import Application from './containers/app';
// eslint-disable-next-line import/no-named-as-default
import client from './apollo';
import configureStore from './modules/store';
import './index.css';
import theme from './shared/config/theme';

const { store, persistor, history } = configureStore();

export default function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ConnectedRouter history={history}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <Application />
            </ThemeProvider>
          </PersistGate>
        </ConnectedRouter>
      </ApolloProvider>
    </Provider>
  );
}
