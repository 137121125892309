// @flow

import React from 'react';
import styled from 'styled-components';

import ErrorMessages from '../ErrorMessages';
import { TextArea } from '.';

const ContainerDiv = styled.div`
  font-size: 80%;
`;

type Props = { errors: Array<{ [string]: string }>, inputID?: string };

export default function TextAreaWithErrors({ errors = [], inputID, ...props }: Props) {
  return (
    <ContainerDiv>
      <TextArea errored={errors.length > 0} id={inputID} {...props} />
      <ErrorMessages errors={errors} />
    </ContainerDiv>
  );
}
