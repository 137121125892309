// @flow

import { loader } from 'graphql.macro';
import hash from 'hash-sum';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';

import { FetchCustomers, sortCustomers } from './List';

import { CurrentUserContext, type CurrentUserType } from '../app/Main';

import validations from '../../modules/models/Validations';

import { naicsCodes2017 } from '../../shared/constants';
import { SubmitButton } from '../../shared/ui/Buttons';
import ConditionalRender from '../../shared/ui/ConditionalRender';
import DropdownFilter from '../../shared/ui/DropdownMenu';
import Dropdown from '../../shared/ui/Dropdown';
import ErrorMessages from '../../shared/ui/ErrorMessages';
import { Checkbox, Fieldset, Form, InputLabel, InputLabelRight, RadioButton } from '../../shared/ui/Forms';
import LabelRightWithErrors from '../../shared/ui/Forms/LabelRightWithErrors';
import TextAreaWithErrors from '../../shared/ui/Forms/TextAreaWithErrors';
import TextInputWithErrors from '../../shared/ui/Forms/TextInputWithErrors';
import Loading from '../../shared/ui/Loading';
import { Label, RedSpan } from '../../shared/ui/Typography';

import { getLanguageName } from './dataTypesAndUtils';

const FetchLanguages = loader('./queries/FetchLanguageList.graphql');

const RowMarginBottom = styled(Row)`
  margin-bottom: 15px;
`;

const A1S_PARENT_ID = 'f61f85c5-d5ce-4d02-89e2-b78cf1c1be33';
const CF1_SSE_MANAGED_PARENT_ID = ['f06c8e3d-93a9-4876-ba6e-148fb6f79fef', '8530b423-3d1d-4b5d-bd60-bc0131297bdf'];
const DEFAULT_PARENT_NAME = 'Area 1 Security - Parent';
const EXCLUDED_KUBRICK_TRANSPORT_DOMAINS = [
  'mail.protection.outlook.com',
  'microsoft.com',
  'outlook.com',
  'protection.outlook.com',
];

export type AccountTypeType = 'Advantage' | 'Enterprise' | 'Internal' | 'Partner' | 'POV' | 'Parent';

type Props = {
  clearErrors: Function,
  errors: Array<any>,
  loading: boolean,
  onCreateNewCustomer: Function,
  setErrors: Function,
};

export default function CustomerForm({ clearErrors, errors, loading, onCreateNewCustomer, setErrors }: Props) {
  const { data, error: getParentsError, loading: getParentsLoading } = useQuery(FetchCustomers);

  const { data: allLanguages } = useQuery(FetchLanguages);
  const languageList = get(allLanguages, 'supportedLanguages.languages', { 'en-US': 'English' });
  const languageOptions = Object.entries(languageList);

  const [customer, setNewCustomer] = useState({
    accountType: 'POV',
    apiEnabled: false,
    bettermailEnabled: false,
    bettermailOnly: false,
    brands: '',
    brsApiEnabled: false,
    cfAccountId: '',
    dailyEmailVolume: 100,
    dnsIps: [],
    dnsRpzEnabled: false,
    email: '',
    firstName: '',
    isPpp: false,
    kubrickLookbackHops: 1,
    kubrickName: '',
    kubrickPrimaryDomain: '',
    kubrickTransport: '',
    langPref: 'en-US',
    lastName: '',
    naics: '',
    name: '',
    newCustomer: true,
    parentId: '',
    phoneNumber: '',
    protectedUsers: 1,
    recursiveDnsEnabled: false,
    startDate: new Date().toISOString(),
  });
  const [parents, setParents] = useState([]);
  const [previousName, setPreviousName] = useState('');
  const [selectedItem, setSelectedItem] = useState('Choose one industry selector');

  const parentAccounts = get(data, 'customers', []).filter(c => c.accountType === 'Parent');

  sortCustomers(parentAccounts);
  const hashedParentAccounts = hash(parentAccounts);
  const hashedParents = hash(parents);

  useEffect(() => {
    const a1sParent = parentAccounts.find(p => p.customerId === A1S_PARENT_ID);
    if (!a1sParent) {
      setNewCustomer({ ...customer, parentId: parentAccounts.length ? parentAccounts[0].customerId : '' });
      setParents(parentAccounts);
    } else {
      const parentsLessArea1 = parentAccounts.filter(p => p.customerId !== A1S_PARENT_ID);
      setNewCustomer({ ...customer, parentId: A1S_PARENT_ID });
      // move A1S to the top of the list
      setParents([a1sParent, ...parentsLessArea1]);
    }
  }, [hashedParentAccounts, hashedParents]);

  const isChildOfCF1SSEManagedParent = CF1_SSE_MANAGED_PARENT_ID.includes(customer.parentId);

  const handleSubmit = (e: SyntheticEvent<HTMLDivElement>, currentUser: ?CurrentUserType) => {
    e.preventDefault();

    clearErrors();
    const { brands, naics } = customer;
    if (!currentUser) return;

    if (isValid(customer, errors, setErrors, isChildOfCF1SSEManagedParent))
      onCreateNewCustomer({
        onboarderEmail: currentUser.email,
        ...customer,
        brands: brands
          .split(';')
          .map(s => s.trim())
          .filter(s => s.length),
        email: email.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        name: name.trim(),
        naics: naics || 81,
        submitted: undefined,
      });
  };

  const handleInputChange = (e: SyntheticInputEvent<*>) => {
    const { value, name } = e.target;
    setNewCustomer({ ...customer, [name]: value });
  };

  const handleCheckboxChange = (e: SyntheticInputEvent<*>) => {
    const { checked, name } = e.target;
    setNewCustomer({ ...customer, [name]: checked });
  };

  const handleRadioChange = (e: SyntheticInputEvent<*>) => {
    e.preventDefault();
    const { name } = e.target;

    if (name === 'Parent') {
      setNewCustomer({
        ...customer,
        accountType: name,
        apiEnabled: false,
        bettermailEnabled: false,
        brsApiEnabled: false,
      });
    } else {
      setNewCustomer({ ...customer, accountType: name });
    }
  };

  const { accountType } = customer;

  useEffect(() => {
    const setApiEnabledForCustomer = () => {
      if (accountType === 'POV' || accountType === 'Enterprise') {
        return true;
      }
      return customer.apiEnabled;
    };
    setNewCustomer({ ...customer, apiEnabled: setApiEnabledForCustomer() });
  }, [accountType]);

  const setKubrickNameAndDefaultBrand = (e: SyntheticInputEvent<*>) => {
    e.preventDefault();
    const { brands, name } = customer;

    let tempCustomer = { ...customer };
    let newBrands = '';

    if (e.target.name === 'name') {
      if (previousName !== name) {
        if (brands.includes(`${previousName};`)) {
          newBrands = brands.replace(`${previousName};`, `${name};`);
        } else {
          newBrands = `${name}; ${brands}`;
        }
      }

      tempCustomer = {
        ...tempCustomer,
        brands: newBrands || brands,
        kubrickName: getKubrickName(e, name),
      };
      setPreviousName(name);
    }

    if (e.target.name === 'kubrickName') {
      if (!kubrickName) {
        tempCustomer = {
          ...tempCustomer,
          kubrickName: getKubrickName(e, name),
        };
      }
    }
    setNewCustomer({ ...tempCustomer });
  };

  const errorsOn = (attrName: string): Array<{ [string]: string }> =>
    errors.filter(error => Object.keys(error).includes(attrName));

  const renderTextInputWithErrors = (prop, propName, label = null, onBlur = undefined) => (
    <Col lg={8} xl={8} style={{ zIndex: '1' }}>
      {label && <Label>{label}</Label>}
      <TextInputWithErrors
        errors={errorsOn(propName)}
        inputID={propName}
        name={propName}
        onBlur={onBlur}
        onChange={handleInputChange}
        value={prop}
      />
    </Col>
  );

  const renderTextAreaWithErrors = (prop, propName, label, onBlur = null, rows = null) => (
    <Col lg={8} xl={8}>
      {label && <Label>{label}</Label>}
      <TextAreaWithErrors
        errors={errorsOn(propName)}
        inputID={propName}
        name={propName}
        onBlur={onBlur}
        onChange={e => handleInputChange(e)}
        rows={rows}
        value={prop}
        type="text"
      />
    </Col>
  );

  const renderRadio = (propName: string, value: ?AccountTypeType | string) => (
    <InputLabel>
      <RadioButton onChange={handleRadioChange} name={propName} id={propName} checked={propName === value} />
      {propName}
    </InputLabel>
  );

  const { apiEnabled, bettermailEnabled, brsApiEnabled } = customer;
  const availableServices = [
    {
      active: bettermailEnabled,
      name: 'bettermailEnabled',
      display: 'Email',
    },
    {
      active: brsApiEnabled,
      name: 'brsApiEnabled',
      display: 'Edge Integration',
    },
    {
      active: apiEnabled,
      name: 'apiEnabled',
      display: 'API',
    },
  ];

  const renderInputLabelRight = (inputLabel, htmlFor = null, red = false, marginTop = 0) => (
    <Col lg={4} xl={4}>
      <InputLabelRight htmlFor={htmlFor} style={{ marginTop }}>
        {inputLabel}
        {red && <RedSpan>*</RedSpan>}
      </InputLabelRight>
    </Col>
  );

  const {
    brands,
    cfAccountId,
    dailyEmailVolume,
    email,
    firstName,
    isPpp,
    kubrickLookbackHops,
    kubrickName,
    kubrickPrimaryDomain,
    kubrickTransport,
    lastName,
    name,
    newCustomer,
    parentId,
    phoneNumber,
    protectedUsers,
  } = customer;

  if (getParentsLoading) return <Loading />;
  if (!parents.length || getParentsError) return <p>Server Error</p>;

  const isNewParent = accountType === 'Parent';

  return (
    <CurrentUserContext.Consumer>
      {({ currentUser }) => (
        <Form disabled={loading || getParentsLoading} onSubmit={e => handleSubmit(e, currentUser)}>
          <Fieldset>
            <RowMarginBottom>
              {renderInputLabelRight('Provisioning Request For:', null, true)}
              <Col lg={8} xl={8}>
                <InputLabel>
                  <RadioButton onChange={handleInputChange} name="newCustomer" value defaultChecked={newCustomer} /> New
                  Customer
                </InputLabel>
              </Col>
            </RowMarginBottom>
          </Fieldset>

          <Fieldset>
            <legend>Customer Information</legend>
            <RowMarginBottom>
              {renderInputLabelRight('Customer To Be Onboarded:', 'name', true, '20px')}
              {renderTextInputWithErrors(
                name,
                'name',
                'Must be unique across all customers',
                setKubrickNameAndDefaultBrand
              )}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('First Name:', 'firstName', true)}
              {renderTextInputWithErrors(firstName, 'firstName')}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Last Name:', 'lastName', true)}
              {renderTextInputWithErrors(lastName, 'lastName')}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Cloudflare Account Tag:', 'cfAccountId', isChildOfCF1SSEManagedParent)}
              {renderTextInputWithErrors(
                cfAccountId,
                'cfAccountId',
                'Customer needs a Cloudflare account Tag to login in the future, get them to create one now to populate.'
              )}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Mobile Phone Number:', 'phoneNumber', false, '20px')}
              {renderTextInputWithErrors(
                phoneNumber,
                'phoneNumber',
                'For sending SMS alerts on system issues and alerts.'
              )}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Email Address:', 'email', true)}
              {renderTextInputWithErrors(email, 'email')}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Preferred Language:', 'language', false, '5px')}
              <Col lg={8} xl={8}>
                <Dropdown
                  dataTestId="language dropdown"
                  disabled={false}
                  options={languageOptions}
                  name="langPref"
                  noMargin
                  onOptionSelected={([code, _]) => {
                    setNewCustomer({
                      ...customer,
                      langPref: `${code.slice(0, 2)}-${code.slice(2).toUpperCase()}`,
                    });
                  }}
                  renderAsOption={([_, option]) => option}
                  value={customer.langPref}
                >
                  {getLanguageName(customer.langPref)}
                </Dropdown>
              </Col>
            </RowMarginBottom>

            <RowMarginBottom>
              <Col lg={4} xl={4}>
                <LabelRightWithErrors htmlFor="accountType" errors={errorsOn('accountType')}>
                  Account Type
                  <RedSpan>*</RedSpan>
                </LabelRightWithErrors>
              </Col>
              <Col lg={8} xl={8}>
                {renderRadio('POV', accountType)}
                {renderRadio('Advantage', accountType)}
                {renderRadio('Enterprise', accountType)}
                {renderRadio('Internal', accountType)}
                {renderRadio('Partner', accountType)}
                {renderRadio('Parent', accountType)}
              </Col>
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Parent Account:', 'parentId', true, '5px')}
              {parentId && parents.length && !getParentsLoading && !getParentsError && (
                <Col lg={8} xl={8}>
                  <Dropdown
                    dataTestId="parent dropdown"
                    disabled={false}
                    options={parents.map(p => p.customerName)}
                    name="parents"
                    noMargin
                    onOptionSelected={value => {
                      setNewCustomer({
                        ...customer,
                        parentId: getParentData(parents, 'customerName', value, 'customerId', A1S_PARENT_ID),
                      });
                    }}
                    renderAsOption={(option: string) => option}
                    value={getParentData(parents, 'customerId', parentId, 'customerName', DEFAULT_PARENT_NAME)}
                  >
                    {getParentData(parents, 'customerId', parentId, 'customerName', DEFAULT_PARENT_NAME)}
                  </Dropdown>
                </Col>
              )}
            </RowMarginBottom>

            <RowMarginBottom>
              {renderInputLabelRight('Brands:', 'brands', !isNewParent, '30px')}
              {renderTextAreaWithErrors(brands, 'brands', 'Use a semi-colon to separate values', null, null)}
            </RowMarginBottom>

            <ConditionalRender condition={!isNewParent}>
              <RowMarginBottom>
                <Col lg={4} xl={4}>
                  <LabelRightWithErrors htmlFor="bettermailEnabled" errors={errorsOn('servicesToBeProvisioned')}>
                    Services to be provisioned
                    <RedSpan>*</RedSpan>
                  </LabelRightWithErrors>
                </Col>
                <Col lg={8} xl={8}>
                  {availableServices.map(service => {
                    const isPovOrEnterprise =
                      service.name === 'apiEnabled' && (accountType === 'POV' || accountType === 'Enterprise');

                    return (
                      <InputLabel disabled={isPovOrEnterprise} key={service.name}>
                        <Checkbox
                          checked={isPovOrEnterprise ? true : service.active}
                          disabled={isPovOrEnterprise}
                          id={service.name}
                          name={service.name}
                          onChange={handleCheckboxChange}
                        />
                        {service.display}
                      </InputLabel>
                    );
                  })}
                </Col>
              </RowMarginBottom>

              <RowMarginBottom>
                {renderInputLabelRight('Pay Per Phish customer?', null, false)}
                <Col lg={8} xl={8}>
                  <Checkbox onChange={handleCheckboxChange} name="isPpp" checked={isPpp} />
                </Col>
              </RowMarginBottom>
            </ConditionalRender>

            <RowMarginBottom>
              {renderInputLabelRight('NAICS Code:', 'naics', !isNewParent, '23px')}
              <Col lg={8} xl={8}>
                {renderNAICSFilter(customer, selectedItem, setSelectedItem, setNewCustomer)}
                <ErrorMessages errors={errorsOn('naics')} />
              </Col>
            </RowMarginBottom>
          </Fieldset>

          <ConditionalRender condition={bettermailEnabled && !isNewParent}>
            <Fieldset>
              <legend>Email Traffic Related Information</legend>

              <RowMarginBottom>
                {renderInputLabelRight('Primary Email Domain:', 'kubrickPrimaryDomain', false)}
                {renderTextInputWithErrors(
                  kubrickPrimaryDomain,
                  'kubrickPrimaryDomain',
                  'Primary domain Area 1 will process emails for (customers can add more via the portal). If API scanning will be used by the customer please leave blank.'
                )}
              </RowMarginBottom>

              <RowMarginBottom>
                {renderInputLabelRight('Email Endpoint', 'kubrickName', false)}
                {renderTextInputWithErrors(
                  kubrickName,
                  'kubrickName',
                  `Provide the desired email endpoint at mxrecord.io where customer should BCC email to if testing via this
            mode. Must be lowercase with no spaces and no dots. Also no need to include "mxrecord.io" -
            it will be added automatically.`,
                  setKubrickNameAndDefaultBrand
                )}
              </RowMarginBottom>
              <RowMarginBottom>
                {renderInputLabelRight('Lookback Hops', 'kubrickLookbackHops', false)}
                {renderTextInputWithErrors(
                  kubrickLookbackHops,
                  'kubrickLookbackHops',
                  'Lookback hops and delivery host will be associated with the primary domain provided above.'
                )}
              </RowMarginBottom>

              <RowMarginBottom>
                {renderInputLabelRight('Delivery Host', 'kubrickTransport', false)}
                {renderTextInputWithErrors(kubrickTransport, 'kubrickTransport')}
              </RowMarginBottom>
              <RowMarginBottom>
                {renderInputLabelRight('Daily Email Volume:', 'dailyEmailVolume', true)}
                {renderTextInputWithErrors(dailyEmailVolume, 'dailyEmailVolume')}
              </RowMarginBottom>
              <RowMarginBottom>
                {renderInputLabelRight('Number of Email Users:', 'protectedUsers', true)}
                {renderTextInputWithErrors(
                  protectedUsers,
                  'protectedUsers',
                  `Number of email users or mailboxes (whichever is higher) we will be protecting. This is not employee
            count which can be much higher than the number of email users.`
                )}
              </RowMarginBottom>
            </Fieldset>
          </ConditionalRender>

          <Row>
            <Col lg={8} xl={8} lgOffset={4} xlOffset={4}>
              <SubmitButton disabled={loading || getParentsLoading} onClick={e => handleSubmit(e, currentUser)}>
                Submit
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      )}
    </CurrentUserContext.Consumer>
  );
}

/*  ---------------------------------------------------------------
    Private Functions
*/
type CustomerType = {
  accountType: AccountTypeType,
  apiEnabled: boolean,
  bettermailEnabled: boolean,
  bettermailOnly: boolean,
  brands: string,
  brsApiEnabled: boolean,
  cfAccountId: string,
  customerId: string,
  customerName: string,
  dailyEmailVolume: number,
  dnsIps: string[],
  dnsRpzEnabled: boolean,
  email: string,
  firstName: string,
  isPpp: boolean,
  kubrickLookbackHops: number,
  kubrickName: string,
  kubrickPrimaryDomain: string,
  kubrickTransport: string,
  lastName: string,
  naics: string,
  name: string,
  newCustomer: boolean,
  parentId: string,
  phoneNumber: string,
  protectedUsers: number,
  recursiveDnsEnabled: boolean,
  startDate: string,
};

const getParentData = (
  parents: Array<CustomerType>,
  foundProp: string,
  value: string = '',
  returnProp: string,
  defaultReturn: string
) => {
  const found = parents.find(p => p[foundProp] === value);
  if (found) return found[returnProp];
  return defaultReturn;
};

const isValid = (customer, errors, setErrors, isCFIDMandatory) => {
  const formErrors = [];
  const {
    accountType,
    apiEnabled,
    brands,
    brsApiEnabled,
    bettermailEnabled,
    cfAccountId,
    dailyEmailVolume,
    email,
    firstName,
    kubrickLookbackHops,
    kubrickName,
    kubrickPrimaryDomain,
    kubrickTransport,
    lastName,
    naics,
    name,
    protectedUsers,
  } = customer;

  if (bettermailEnabled) {
    if (!validations.domain(kubrickPrimaryDomain)) {
      formErrors.push({ kubrickPrimaryDomain: "'Primary Email Domain' must be a domain" });
    }
    if (!validations.numericality(kubrickLookbackHops)) {
      formErrors.push({ kubrickLookbackHops: "'Lookback Hops' must be a number" });
    }
    if (!validations.presence(dailyEmailVolume)) {
      formErrors.push({ dailyEmailVolume: "'Daily Email Volume' can't be blank" });
    }
    if (!validations.numericality(dailyEmailVolume)) {
      formErrors.push({ dailyEmailVolume: "'Daily Email Volume' must be a number" });
    }
    if (!validations.numericality(protectedUsers)) {
      formErrors.push({ protectedUsers: "'Number of Email Users' must be a number" });
    }
    if (!validations.within(1, 5000000, protectedUsers)) {
      formErrors.push({
        protectedUsers: "'Number of Email Users' should be a number between 1 and 5000000",
      });
    }
    if (!validations.presence(kubrickName)) {
      formErrors.push({ kubrickName: "'Email Endpoint' can't be blank" });
    }
    if (!validations.exclude(kubrickName, ['@'])) {
      formErrors.push({
        kubrickName: "Please provide the part of the email before '@'",
      });
    }
    if (kubrickName.endsWith('.')) {
      formErrors.push({
        kubrickName: "'Email Endpoint' cannot end with a '.'",
      });
    }

    if (!validations.domain(kubrickTransport) && !validations.ip(kubrickTransport))
      formErrors.push({
        kubrickTransport: "'Delivery Host' must be a valid domain, hostname or IP address",
      });

    if (EXCLUDED_KUBRICK_TRANSPORT_DOMAINS.includes(kubrickTransport))
      formErrors.push({
        kubrickTransport: "Invalid 'Delivery Host'. Unable to route mail to this transport.",
      });
  }

  if (!validations.presence(name) || name.endsWith('.')) {
    formErrors.push(
      name.endsWith('.')
        ? { name: "'Customer' name cannot end with a '.'" }
        : { name: "'Customer' name can't be blank" }
    );
    setErrors(formErrors);
    return !formErrors.length;
  }

  if (!validations.presence(firstName)) formErrors.push({ firstName: "'First name' can't be blank" });

  if (!validations.presence(lastName)) formErrors.push({ lastName: "'Last name' can't be blank" });

  if (!validations.presence(email)) formErrors.push({ email: "'Email' can't be blank" });

  if (!validations.email(email.trim())) formErrors.push({ email: "'Email' must be formatted like an email" });

  // check first char of name
  if (!validations.validChars(name[0] ? name[0] : '', /[a-zA-Z0-9]/))
    formErrors.push({
      name: "'Customer' name must start with characters: 'A-Z' or '0-9'",
    });

  if (accountType !== 'Parent') {
    if (brands.split(';').length === 0) formErrors.push({ brands: 'You must provide at least one brand name.' });

    if (!validations.selection(apiEnabled, brsApiEnabled, bettermailEnabled)) {
      formErrors.push({ bettermailEnabled: 'At least one service must be provisioned' });
    }

    if (!validations.presence(naics)) formErrors.push({ naics: "'NAICS code' can't be blank" });
  }

  if (isCFIDMandatory) {
    if (!validations.presence(cfAccountId)) formErrors.push({ cfAccountId: "'Cloudflare Account Tag' can't be blank" });
  }

  if (cfAccountId) {
    // alphanumeric characters and digits only like: 020c8779d6ac99685f056a2729b3c2b1
    const regexMatch = /(?=[a-z0-9]*$)/g;
    if (!(!!regexMatch.test(cfAccountId) && cfAccountId.match(regexMatch).length === 33))
      formErrors.push({
        cfAccountId: "'Cloudflare Account Tag' must have only characters: 'a-z' and '0-9'",
      });
  }

  setErrors(formErrors);
  return !formErrors.length;
};

const renderNAICSFilter = (customer, selectedItem, setSelectedItem, setNewCustomer) => {
  const handleFilterClick = value => {
    setSelectedItem(value);
    setNewCustomer({ ...customer, naics: Number(value.slice(0, 2)) });
  };

  // eslint-disable-next-line no-shadow
  const mapItems = naicsCodes2017 => {
    const result = Object.keys(naicsCodes2017).map(selector => `${selector} ${naicsCodes2017[selector]}`);
    return [...result.values()];
  };

  return (
    <Col lg={8} xl={8}>
      <Label>
        For more information look{' '}
        <a
          href="https://www.census.gov/cgi-bin/sssd/naics/naicsrch?chart=2017"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </Label>
      <DropdownFilter handleChange={handleFilterClick} options={mapItems(naicsCodes2017)} selected={selectedItem} />
    </Col>
  );
};

// FYI DNS has a hard dependency on this format and needs to be updated in tandem if below changes
// https://github.area1security.com/engineering/voldemort/pull/165/files
// kubrickName cannot have any chars except alphanumeric, underscore, and hyphen
const getKubrickName = (event, name) =>
  event.target.name === name
    ? event.target.value
    : name
        .replace(/[^a-zA-Z0-9_-]/g, '')
        .replace(/\s+/g, '')
        .toLowerCase();
