/**
 * Link Subcomponent for SideBarNav Component
 */

import React from 'react';
// import { Link as RouterLink, withRouter } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import arrow from './arrow.svg';

const Link = ({
  children, // eslint-disable-line react/prop-types
  className, // eslint-disable-line react/prop-types
  to, // eslint-disable-line react/prop-types
  disabled, // eslint-disable-line react/prop-types
}) => {
  const linkProps = {
    ...(!disabled && { to }),
  };
  return (
    <li className={className}>
      <RouterLink {...linkProps} activeClassName="active" exact>
        {children}
      </RouterLink>
    </li>
  );
};

export default styled(Link)`
  font-size: 14px;
  font-weight: 300;
  height: 32px;

  a {
    color: #666;
    display: block;
    text-decoration: none;

    ${props =>
      props.disabled &&
      css`
        color: #cfcfcf;
        &:hover {
          color: #cfcfcf !important;
          cursor: text;
        }
      `};

    &.active {
      background: transparent url(${arrow}) center right no-repeat;
      background-size: 5px 10px;
      color: #44b9f8;
    }
  }
`;
