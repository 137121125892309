// @flow

import { rem, rgba } from 'polished';
import React, { type Node } from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';

import Modal from './Modal';

import Button from '../../atoms/Button';
import Section from '../../atoms/Section';
import ConditionalRender from '../../ConditionalRender';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Centered = styled.div`
  position: relative;
  text-align: center;
`;

const Container = styled(animated.div).attrs(({ open }) => ({ 'aria-hidden': !open, role: 'alert' }))`
  background-color: ${({ dark, theme }) => (dark ? theme.colors.ghostWhite : 'transparent')};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  left: 50%;
  min-height: ${rem(150)};
  margin: ${({ theme }) => rem(theme.spacing.lg)};
  opacity: 0;
  padding: ${({ theme }) => rem(theme.spacing.lg)};
  padding-top: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: ${rem(320)};
  max-width: ${({ expanded }) => (expanded ? rem(820) : rem(480))};
  z-index: 999;

  ${({ expanded }) =>
    expanded &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};

  ${({ wide }) =>
    wide &&
    css`
      min-width: ${rem(600)};
      max-width: 80%;
    `};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type EventFunction = (?SyntheticEvent<HTMLDivElement>) => void;

type Props = {
  busy?: boolean,
  buttonDisabled?: boolean,
  buttonText?: Node | string,
  cancelText?: string,
  dark?: boolean,
  dataTestId?: string,
  message?: Node,
  onActionCancel?: EventFunction,
  onActionConfirm?: EventFunction,
  open?: boolean,
  wide?: boolean,
  zebraStripe?: boolean,
};

function AlertDialog(props: Props) {
  const {
    busy = false,
    buttonDisabled = false,
    buttonText = 'buttons.delete',
    cancelText = 'Cancel',
    dark = true,
    dataTestId = 'molecule-alert-dialog',
    message = 'dialogs.confirmDelete',
    open = false,
    wide = false,
    zebraStripe = true,
  } = props;

  const transitions = useTransition(open, null, {
    config: { tension: 500, friction: 35, mass: 1 },
    from: { opacity: 0, transform: 'translate(-50%, -100%)' },
    enter: { opacity: 1, transform: 'translate(-50%, -50%)' },
    leave: { opacity: 0, transform: 'translate(-50%, -100%)' },
  });

  return transitions.map(
    ({ item, key, props: style }) =>
      item && (
        <Container dark={dark ? 'true' : undefined} data-testid={dataTestId} key={key} style={style} wide={wide}>
          <>
            <Section zebraStripe={zebraStripe}>
              <Centered>{typeof message === 'string' ? message : message}</Centered>
            </Section>
            <Centered>
              <ConditionalRender condition={!!props.onActionCancel}>
                <Button dataTestId="button-cancel" value={false} onClick={props.onActionCancel} muted>
                  {cancelText}
                </Button>
              </ConditionalRender>
              <ConditionalRender condition={!!buttonText}>
                <Button
                  dataTestId="button-confirm"
                  disabled={busy || buttonDisabled}
                  icon={busy && !buttonDisabled && 'spinner'}
                  value
                  onClick={props.onActionConfirm}
                >
                  {typeof buttonText === 'string' ? buttonText : buttonText}
                </Button>
              </ConditionalRender>
            </Centered>
          </>
        </Container>
      )
  );
}

AlertDialog.Modal = Modal;

export default AlertDialog;
