import { get } from 'lodash';

export const LANGUAGE_DICTIONARY = {
  de: {
    code: 'de-DE',
    name: 'Deutsch', // German
  },
  en: {
    code: 'en-US',
    name: 'English',
  },
  es: {
    code: 'es-ES',
    name: 'Español',
  },
  fr: {
    code: 'fr-FR',
    name: 'Français',
  },
  it: {
    code: 'it-IT',
    name: 'Italiana',
  },
  ja: {
    code: 'ja-JP',
    name: '日本語', // Japanese
  },
  ko: {
    code: 'ko-KR',
    name: '한국인', // Korean
  },
  pt: {
    code: 'pt-BR',
    name: 'Português',
  },
  'zh-CN': {
    code: 'zh-CN',
    name: '简体中文', // Chinese (simplified)
  },
  'zh-TW': {
    code: 'zh-TW',
    name: '中國傳統的', // Chinese (traditional)
  },
};

// converts language code to full language name ('pt-BR' -> 'Português')
export function getLanguageName(languageCode) {
  const abbreviation = Object.keys(LANGUAGE_DICTIONARY).find(l => LANGUAGE_DICTIONARY[l].code === languageCode);
  const languageEntry = get(LANGUAGE_DICTIONARY, abbreviation);

  return languageEntry.name;
}
