// @flow

import styled, { css } from 'styled-components';

const Row = styled.tr`
  background: inherit;
  border-color: ${({ theme }) => theme.colors.antiFlashWhite};
  border-style: solid;
  border-width: 0;

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom-width: 1px;
    `};

  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left-width: 1px;
    `};

  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right-width: 1px;
    `};

  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top-width: 1px;
    `};
`;

export default Row;
