// @flow

import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Col, Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';

import CustomerForm from './Form';
import ErrorMessages from '../../shared/ui/ErrorMessages';
import { H1, P, RedSpan } from '../../shared/ui/Typography';
import { FetchCustomers } from './List';
import { Error as FlashError, Notice } from '../../shared/ui/FlashMessages';
import { CurrentUserContext } from '../app/Main';

const CreateCustomer = loader('./queries/CreateCustomer.graphql');

export default function CustomersNew() {
  const [errors, setErrorsState] = useState([]);
  const [serverError, setServerError] = useState('');

  const history = useHistory();

  const setFormErrors = (newErrors: Array<Object>) => {
    setErrorsState(newErrors);
    window.scrollTo(0, 0);
  };

  const [createCustomer, { loading }] = useMutation(CreateCustomer, {
    update(cache, { data }) {
      const { createCustomer: newCustomer } = data;
      const { customers } = cache.readQuery({ query: FetchCustomers });
      const { accountType, customerId, customerName } = newCustomer;
      cache.writeQuery({
        data: { customers: [{ accountType, customerId, customerName, __typename: 'Customer' }, ...customers] },
        query: FetchCustomers,
      });
    },
  });

  return (
    <CurrentUserContext.Consumer>
      {({ currentUser, handleCurrentUser }) => {
        const handleCreateNewCustomer = async input => {
          if (serverError) setServerError('');
          try {
            const { data } = await createCustomer({ variables: { input } });
            if (data.createCustomer && data.createCustomer.kubrickName) {
              history.push(`/a1s-customers/show/${data.createCustomer.kubrickName}`, {
                customer: data.createCustomer,
                notice: 'Customer onboarded successfully.',
              });
            }
          } catch (err) {
            const { result, statusCode } = err.networkError;

            if (statusCode === 401) {
              handleCurrentUser(undefined, result);
              history.push('/login');
              return null;
            }

            if (result.error && result.error.i18nkey) {
              setServerError(result.error.i18nkey);
            } else {
              setServerError(result.error);
            }
          }
          return 0;
        };

        return (
          <Row>
            <Col lg={10}>
              <H1>Onboarding Request</H1>
              <P>
                Submit customer on-boarding requests via this form. Once provisioned, confirmations will be emailed to
                requester as well as the customer contact provided via the form.
              </P>
              <P>
                Your email address ({currentUser ? currentUser.email : ''}) will be recorded when you submit this form.
              </P>
              <P>
                <RedSpan>* Required</RedSpan>
              </P>
            </Col>
            {serverError && (
              <Col lg={10}>
                <FlashError>{serverError}</FlashError>
              </Col>
            )}
            {errors && (
              <Col lg={10}>
                <Col lg={10} lgOffset={4}>
                  <ErrorMessages errors={errors}>
                    <p style={{ marginBottom: 10 }}>Please see errors below...</p>
                  </ErrorMessages>
                </Col>
              </Col>
            )}
            <Col lg={10}>
              <Row>
                <Col lg={10}>
                  {loading && <Notice>Please wait...</Notice>}

                  <CustomerForm
                    clearErrors={() => setErrorsState([])}
                    onCreateNewCustomer={handleCreateNewCustomer}
                    loading={loading}
                    errors={errors}
                    setErrors={setFormErrors}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }}
    </CurrentUserContext.Consumer>
  );
}
