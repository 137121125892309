// @flow

import { onError } from 'apollo-link-error';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import * as camelCase from 'camelcase';
// eslint-disable-next-line camelcase
import * as snake_case from 'snake-case';

export const cache = new InMemoryCache({
  // This is needed since the API doesn't return a `id` key back but instead returns
  // `customer_id`.
  dataIdFromObject: object => {
    // eslint-disable-next-line no-underscore-dangle
    switch (object.__typename) {
      case 'AdminUser':
        return object.userId;
      case 'Customer':
        return object.customerId;
      case 'CustomerUser':
        return object.userId;
      case 'DeleteCheck':
        return object.customerId;
      case 'User':
        return object.userId;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError && networkError.statusCode === 401) {
    window.location.href = '/login';
    return null;
  }

  return forward(operation);
});

const restLink = new RestLink({
  endpoints: {
    parentUserReassignment: {
      uri: '/api',
      responseTransformer: async response => {
        const { data } = await response.json();
        return {
          users: data.users.map(user => ({
            ...user,
            __typename: 'CustomerUser',
          })),
          failures: data.failures,
        };
      },
    },
  },
  // This makes the keys returned-from/sent-to the REST endpoint more GraphQL-like.
  fieldNameDenormalizer: key => snake_case(key),
  fieldNameNormalizer: key => (/\s|\./.test(key) ? key : camelCase(key)),
  responseTransformer: async response => response.json().then(({ data }) => data),
  uri: '/api',
});

const link = ApolloLink.from([errorLink, restLink]);

const config = { cache, link };

const client = new ApolloClient(config);
export default client;
