// @flow

import { Col, Row } from 'react-flexbox-grid';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { GrayButton } from '../Buttons';
import src from './arrow-down.png';

const StyledAlphaButton = styled(GrayButton)`
  background-color: #fff;
  border: 1px solid rgb(239, 239, 239);
  margin: 0px -1px -1px 0px;
  min-width: 534px;
  padding-left: 9px;
  text-align: left;

  &:hover {
    background-color: rgb(239, 239, 239);
    color: rgb(102, 102, 102);
  }

  ${({ small }) =>
    small &&
    css`
      min-width: 175px;
      border: border: 1px solid #00bfff;
      vertical-align:middle;
    `};
`;

const StyledContainer = styled.div`
  display: block;
  position: absolute;
`;

const StyledImg = styled.img`
  bottom: 0;
  float: right;
  height: 11px;
  margin-top: 2px;
  margin-right: -7px;
  right: 18px;
  top: 0px;
`;

const StyledUl = styled.ul`
  margin: 0px;
  padding-left: 0px;
  position: relative;
`;

const StyledOpenUl = styled.ul`
  height: 273px;
  overflow-y: scroll;
  margin: 0px;
  padding-left: 0px;
  position: relative;
  width: 549px;
  z-index: 2;

  ${({ small }) =>
    small &&
    css`
      height: 100%;
      overflow-y: auto;
      width: 200px;
      border: 1px solid rgb(239, 239, 239);
    `};
`;

type DropdownFilterProps = {
  handleClick: Function,
  small?: boolean,
  value: string,
};

const DropdownFilterLink = ({ handleClick, small = false, value }: DropdownFilterProps) => {
  const displayValue = value;

  return (
    <StyledAlphaButton onClick={handleClick} data-value={value} small={small}>
      {displayValue}
    </StyledAlphaButton>
  );
};

type Props = {
  handleChange: Function,
  hideEveryoneElse?: (id: number) => void,
  id?: number | null,
  options: Array<any>,
  selected: string,
  small?: boolean,
};

export default function DropdownFilter({
  handleChange,
  hideEveryoneElse,
  id,
  options = [],
  selected,
  small = false,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    if (!expanded && hideEveryoneElse && id) {
      hideEveryoneElse(id);
    }
    setExpanded(!expanded);
  };

  const handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.getAttribute('data-value');
    handleChange(value);

    toggleExpanded();
  };

  const dropdownOptions = options.map(o => (
    <DropdownFilterLink key={o} value={o} handleClick={handleClick} small={small} />
  ));

  return (
    <Row style={{}}>
      <Col>
        <StyledContainer>
          {expanded ? (
            <StyledOpenUl small={small}>{dropdownOptions}</StyledOpenUl>
          ) : (
            <StyledUl>
              <StyledAlphaButton onClick={toggleExpanded} small={small}>
                {selected} <StyledImg alt="arrow" src={src} />
              </StyledAlphaButton>
            </StyledUl>
          )}
        </StyledContainer>
      </Col>
    </Row>
  );
}
