// @flow

/**
 * Tooltip Component
 *
 * Renders a tooltip that displays more information for when the Icon subcomputer is hovered over.
 */

import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

import Arrow from './Arrow';
import Content from './Content';

type Props = { anchorRef: ?HTMLElement, children: Node, className: string, position: string };

function Tooltip({ anchorRef, children, className, position = 'left' }: Props) {
  let ref: ?HTMLDivElement = null;

  const assignRef = (newRef: ?HTMLDivElement) => {
    ref = newRef;
  };

  const styles = {};

  if (ref && anchorRef) {
    if (window.getComputedStyle(anchorRef.parentNode, null).getPropertyValue('position') !== 'relative') {
      // eslint-disable-next-line no-console
      console.warn(
        "anchorRef's parent **must** have position: relative in order for Tooltip's positioning to work properly."
      );
    }

    const anchor = anchorRef.getBoundingClientRect();
    // $FlowIssue I'm checking if parentNode is available on line #44 but Flow keeps complaining
    const parent = anchorRef.parentNode.getBoundingClientRect();
    // $FlowIssue I'm checking if parentNode is available on line #44 but Flow keeps complaining
    const tooltip = ref.getBoundingClientRect();

    let x = anchor.left - parent.left;
    let y = anchor.top - parent.top;

    switch (position) {
      case 'bottom':
        x = x - tooltip.width / 2 + anchor.width / 2;
        y += anchor.height;
        break;
      case 'left':
        x -= tooltip.width;
        y = y - tooltip.height / 2 + anchor.height / 2;
        break;
      case 'right':
        x += anchor.width;
        y = y - tooltip.height / 2 + anchor.width / 2;
        break;
      case 'top':
        x = x - tooltip.width / 2 + anchor.width / 2;
        y -= tooltip.height;
        break;
      default:
        x = tooltip.width;
        y = tooltip.height;
    }

    styles.transform = `translate(${x}px, ${y}px)`;
  }
  return (
    <div className={className} ref={assignRef} style={styles}>
      <Arrow position={position} />
      <Content position={position}>{children}</Content>
    </div>
  );
}

export default styled(Tooltip)`
  left: 0;
  min-width: 200px;
  max-width: 400px;
  opacity: 0;
  position: absolute;
  transition: visibility 0s linear 150ms, opacity 150ms linear;
  visibility: hidden;
  top: 0;
  z-index: 999;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      transition-delay: 0s;
      visibility: visible;
    `};
`;
