// @flow

import React from 'react';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';

import ErrorMessage from './ErrorMessage';

export const ErrorsContainer = styled.div`
  color: rgb(185, 185, 185);
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

type Props = { children?: React$Element<any>, errors: Array<any> };

export default function ErrorMessages({ children, errors = [] }: Props) {
  if (errors.length <= 0) return false;
  return (
    <ErrorsContainer>
      {children}
      {errors.map(error => Object.keys(error).map(key => <ErrorMessage key={uuidv1()}>- {error[key]}</ErrorMessage>))}
    </ErrorsContainer>
  );
}
