// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components/macro';

const Cell = styled.td`
  font-size: ${rem(14)};
  padding: 0;
  padding-top: ${({ theme }) => rem(theme.spacing.sm)};
  word-wrap: break-word;

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `};

  ${({ theme, verticalAlign }) =>
    verticalAlign &&
    css`
      padding-top: ${rem(theme.spacing.md)};
      vertical-align: ${verticalAlign} !important;
    `};

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

export default Cell;
