/**
 * Tooltip Subcomponent for Info
 *
 * Renders a tooltip that displays more information for when the Icon subcomputer is hovered over.
 */

import styled from 'styled-components';

import Tooltip from '../Tooltip';

export default styled(Tooltip)`
  color: #000;
  font-size: 12px;
  line-height: 1.25;
  text-transform: none;
`;
