// @flow

import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { useMutation } from 'react-apollo';

import validations from '../../modules/models/Validations';

import Button from '../../shared/ui/atoms/Button';
import Form from '../../shared/ui/atoms/Form';
import PaddedContent from '../../shared/ui/atoms/PaddedContent';
import Title from '../../shared/ui/atoms/Title';

import AlertDialog from '../../shared/ui/molecules/AlertDialog';
import ErrorMessage from '../../shared/ui/molecules/ErrorMessage';
import Field from '../../shared/ui/molecules/Field';
import TextField from '../../shared/ui/molecules/TextField';

import { FetchCustomerUsers } from './CustomerUserDetail';

const CreateUserMutation = loader('./queries/CreateUser.graphql');

const DEMO_CUSTOMER_ID = 'a4e5ec2c-79d2-4778-81fd-41b0f7778eec';

type FormValuesType = {
  email: string,
  firstName: string,
  lastName: string,
};

type Props = {
  alertOpen: boolean,
  onActionCancel: () => void,
  setMessage: (message: string) => void,
  setSuccess: (success: boolean) => void,
};

export default function NewUserDialog({ alertOpen, onActionCancel, setMessage, setSuccess }: Props) {
  const [createUser, { loading }] = useMutation(CreateUserMutation, {
    update(cache, { data }) {
      const { customerUser } = data;
      updateCacheAfterCreate(cache, customerUser);
    },
  });

  const handleFormSubmit = async (values, resetForm) => {
    const { email, firstName, lastName } = values;
    const input = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      provenance: 'gangplank',
    };

    try {
      await createUser({ variables: { demoCustomerId: DEMO_CUSTOMER_ID, input } });
      setMessage(`Successfully created demo user for ${email.toLowerCase()}`);
      setSuccess(true);
    } catch (e) {
      setMessage(get(e, 'networkError.result.error', 'Error creating demo account user'));
      setSuccess(false);
    }
    onActionCancel();
    resetForm();
  };

  const initialFormValues = {
    email: '',
    firstName: '',
    lastName: '',
  };

  return (
    <AlertDialog
      busy={loading}
      buttonText=""
      expanded
      message={
        <>
          <Title>Create New User For Demo Account</Title>
          <PaddedContent pushBottom="sm" pushTop="sm" />

          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
            validate={validate}
            validateOnChange={false}
          >
            {({ errors, handleChange, handleSubmit, setErrors, values }) => (
              <Form onSubmit={handleSubmit}>
                <PaddedContent pushBottom="md" pushTop="md">
                  {errors.length > 0 && (
                    <ErrorMessage>
                      {errors.map(error => (
                        <p key={error}>{error}</p>
                      ))}
                    </ErrorMessage>
                  )}

                  <TextField
                    label="Email"
                    name="email"
                    onChange={value => {
                      setErrors([]);
                      handleChange(value);
                    }}
                    value={values.email}
                    placeholder="user@domain.com"
                  />
                  <TextField
                    label="Full Name"
                    name="firstName"
                    onChange={value => {
                      setErrors([]);
                      handleChange(value);
                    }}
                    value={values.firstName}
                    placeholder="First Name"
                  />
                  <TextField
                    label=""
                    name="lastName"
                    onChange={value => {
                      setErrors([]);
                      handleChange(value);
                    }}
                    value={values.lastName}
                    placeholder="Last Name"
                  />

                  <Field>
                    <Button disabled={loading} icon={loading ? 'spinner' : 'add'} type="submit">
                      Send Invitation
                    </Button>
                    <Button dataTestId="button-cancel" value={false} onClick={onActionCancel} muted>
                      Cancel
                    </Button>
                  </Field>
                </PaddedContent>
              </Form>
            )}
          </Formik>
        </>
      }
      open={alertOpen}
      wide
    />
  );
}

/*
    Private Functions
*/

const validate = (values: FormValuesType) => {
  let errors = [];
  const { email, firstName, lastName } = values;

  if (!validations.email(email)) errors = [...errors, 'Email needs to be formated as an email'];
  if (!validations.presence(email)) errors = [...errors, 'Email cannot be blank'];
  if (!validations.presence(firstName)) errors = [...errors, 'First Name cannot be blank'];
  if (!validations.presence(lastName)) errors = [...errors, 'Last Name cannot be blank'];

  return errors;
};

type UserType = {
  customerId: string,
  customerName: string,
  deletedAt: string,
  email: string,
  firstName: string,
  lastName: string,
  role: string,
  userId: number,
};

const updateCacheAfterCreate = (cache: Object, newUser: UserType) => {
  try {
    const { customerUsers } = cache.readQuery({
      query: FetchCustomerUsers,
      variables: { customerId: DEMO_CUSTOMER_ID },
    });

    cache.writeQuery({
      data: { customerUsers: [newUser, ...customerUsers] },
      query: FetchCustomerUsers,
      variables: { customerId: DEMO_CUSTOMER_ID },
    });
  } catch (error) {
    // check Sentry for potential errors
  }
};
