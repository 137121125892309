import isIp from 'is-ip';
import isValidDomain from 'is-valid-domain';

const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validations = {
  presence(value) {
    if (!value) {
      return false;
    }
    if (typeof value === 'string') {
      return !!value.trim();
    }

    return true;
  },

  email(value) {
    if (!value) {
      return false;
    }

    return emailRegexp.test(value);
  },

  exclude(value, forbiddenChars: string[]) {
    return !forbiddenChars.some(char => value.includes(char));
  },

  domain(value: string): boolean {
    if (!value) {
      return true;
    }
    // wildcard allows for '.*jira.com'
    return isValidDomain(value, { subdomain: true, wildcard: true });
  },

  ip(value: string): boolean {
    if (!value) {
      return true;
    }

    return isIp(value);
  },

  matches(value: string, second: string) {
    if (!value) {
      return true;
    }

    return value === second;
  },

  maxLength(max: number, value: string) {
    if (!value) {
      return true;
    }

    return value.length <= max;
  },

  minLength(min: number, value: string) {
    if (!value) {
      return true;
    }

    return value.length >= min;
  },

  numericality(value) {
    const regexp = new RegExp(/^\d+$/);

    return regexp.test(value);
  },

  passwordFormat(password) {
    return (
      password.match(/\d/) !== null &&
      password.match(/[A-Z]/) !== null &&
      password.match(/[a-z]/) !== null &&
      password.match(/[\W_]/) !== null
    );
  },

  // Returns true if at least one of `choices` returns true
  selection(...choices) {
    return choices.some(x => x === true);
  },

  validChars(value: string, validCharsRegex: RegExp) {
    return validCharsRegex.test(value);
  },

  within(start, stop, value) {
    const range = [...Array(stop + 2 - (start + 1))].map((_, i) => start + i);
    return range.includes(parseInt(value, 10));
  },

  uniqueness(id, value, existingIdAndValue) {
    const existingValues = existingIdAndValue.reduce((m, e) => {
      const [existingId, existingValue] = e;
      if (id !== existingId) m.push(existingValue.trim().toLowerCase());
      return m;
    }, []);

    return !existingValues.includes(value.trim().toLowerCase());
  },
};

export default validations;
