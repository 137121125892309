// @flow

import { Col, Row } from 'react-flexbox-grid';
import { loader } from 'graphql.macro';
import { Mutation } from 'react-apollo';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { CurrentUserContext } from '../app/Main';
import validations from '../../modules/models/Validations';

import { Error as FlashError } from '../../shared/ui/FlashMessages';
import Info from '../../shared/ui/Info';
import { InputLabel, PasswordInput } from '../../shared/ui/Forms';
import { SubmitButton } from '../../shared/ui/Buttons';

const ChangePassword = loader('./queries/ChangePasswordMutation.graphql');

type Props = {
  id: ?number,
  name: string,
  onSuccess: () => void,
};

export default function ResetPasswordForm({ id, name = '', onSuccess }: Props) {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState([]);

  const { handleCurrentUser } = useContext(CurrentUserContext);

  const handleChange = (e, type) => {
    if (type === 'password1') setPassword1(e.target.value);
    else setPassword2(e.target.value);
    if (errors.length) setErrors([]);
  };

  return (
    <Mutation mutation={ChangePassword}>
      {(changePassword, { error, loading }) => {
        const handleSubmit = async e => {
          e.preventDefault();
          if (validateFields(password1, password2, setErrors)) {
            const input = { password: password1 };
            const { data } = await changePassword({ variables: { id, input } });
            if (data && data.changePassword && !error && !loading) {
              handleCurrentUser(data.changePassword);
              onSuccess();
            }
          }
        };
        return (
          <form onSubmit={handleSubmit}>
            {!!errors.length && errors.map(err => <FlashError>{err}</FlashError>)}
            {error && <FlashError>Login Error</FlashError>}
            <Row center="lg">
              <Col lg={12}>
                <SpacedInputLabel>Welcome, {name}</SpacedInputLabel>
              </Col>
            </Row>
            <Row center="lg">
              <Col lg={12}>
                <SpacedInputLabel>
                  <strong>PLEASE CREATE A NEW PASSWORD</strong>
                  <Info tooltipPosition="right">
                    <p>Passwords must contain</p>
                    <p>
                      8-50 characters with:
                      <br />
                      at least 1 number
                      <br />
                      at least 1 uppercase letter
                      <br />
                      at least 1 lowercase letter
                      <br />
                      at least 1 symbol
                    </p>
                  </Info>
                </SpacedInputLabel>
              </Col>
            </Row>

            <SpacedRow>
              <Col lg={12}>
                <InputLabel>Password:</InputLabel>
                <PasswordInput
                  autocomplete="off"
                  name="pw1"
                  onChange={e => handleChange(e, 'password1')}
                  placeholder="password"
                />
              </Col>
            </SpacedRow>
            <SpacedRow>
              <Col lg={12}>
                <InputLabel>Confirm Password:</InputLabel>
                <PasswordInput
                  autocomplete="off"
                  name="pw2"
                  onChange={e => handleChange(e, 'password2')}
                  placeholder="confirm password"
                />
              </Col>
            </SpacedRow>
            <SpacedRow>
              <Col lg={12}>
                {!loading ? (
                  <SubmitButton
                    disabled={errors.length || loading}
                    onClick={handleSubmit}
                    style={{ margin: '10px 0px' }}
                  >
                    Save Password
                  </SubmitButton>
                ) : (
                  <SubmitButton disabled style={{ margin: '10px 0px' }}>
                    Submitting...
                  </SubmitButton>
                )}
              </Col>
            </SpacedRow>
          </form>
        );
      }}
    </Mutation>
  );
}

/*
  Private functions
*/

const validateFields = (password1, password2, setErrors) => {
  const errors = [];
  if (!validations.presence(password1) || !validations.presence(password2)) errors.push("'Password' can't be blank");
  if (!validations.matches(password1, password2)) errors.push("'Passwords' must match");
  if (!validations.minLength(8, password1)) errors.push("'Password must be at least 8 chars'");
  if (!validations.maxLength(50, password1)) errors.push("'Password' must be fewer than 50 chars");
  if (!validations.passwordFormat(password1)) errors.push("'Password' format doesn't match the requirements");
  if (errors.length) setErrors(errors);
  return !errors.length;
};

/*
  Private styles
*/

const SpacedInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
  text-transform: capitalize;
`;

const SpacedRow = styled(Row)`
  margin: 20px, 0px !important;
`;
