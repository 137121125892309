import styled, { css } from 'styled-components';

export const Form = styled.form`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      & > * {
        pointer-events: none;
      }
    `};
`;

export const Fieldset = styled.fieldset`
  border-width: 0;

  & > legend {
    background-color: #00bbff;
    color: #fff;
    display: block;
    font-size: 18px;
    height: 40px;
    letter-spacing: 0.25px;
    line-height: 40px;
    margin: 0 0 20px -30px;
    padding-left: 30px;
    width: 315px;

    &:after {
      border-top: 40px solid #00bbff;
      border-right: 15px solid transparent;
      content: '';
      display: block;
      height: 0;
      left: 317px;
      position: relative;
      top: -40px;
      width: 0;
      z-index: 1;
    }
  }
`;

export const InputLabel = styled.label`
  background-color: ${props => props.errored && 'pink'};
  color: rgb(51, 51, 51);
  display: block;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: left;
`;

export const InputLabelRight = styled(InputLabel)`
  text-align: right;
`;

export const TextInput = styled.input.attrs({ type: 'text' })`
  border: 1px solid ${props => (props.errored ? '#ff2c41' : '#eee')};
  display: block;
  font-size: 16px;
  padding: 5px;
  outline: none;
  position: relative;
  width: 100%;
`;

export const PasswordInput = styled.input.attrs({ type: 'password' })`
  border: 1px;
  border-style: inset;
  font-size: 16px;
  padding: 5px;
  outline: none;
  width: 100%;
`;

export const TextArea = styled.textarea`
  border: 1px solid ${props => (props.errored ? '#ff2c41' : '#eee')};
  font-size: 16px;
  outline: none;
  padding: 5px;
  resize: none;
  width: 100%;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
  outline: ${props => props.errored && '1px solid #ff2c41'};
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  margin-right: 10px;
  outline: ${props => props.errored && '1px solid #ff2c41'};
`;
