// @flow

import { Query, Mutation } from 'react-apollo';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AngryButton, Button, SubmitButton } from '../../shared/ui/Buttons';
import { Body, Cell, Head, Header, Row, Container as Table } from '../../shared/ui/molecules/Table';
import { Checkbox, InputLabel } from '../../shared/ui/Forms';
import {
  EditSubscriptionMutation,
  EMAIL_REPORT_TYPES,
  EmailSubscriptionsQuery,
  UnsubscribeFromReportsMutation,
} from './dataAndQueries';
import { StyledButtonDiv, StyledCell } from '../customers/List';
import { StyledTextInput } from '../../shared/ui/Search';

const P = styled.p`
  display: flex;
  justify-content: center;
`;

const SmlAngryButton = styled(AngryButton)`
  min-width: 65px;
  padding: 0 5px;
`;

const SmlButton = styled(Button)`
  min-width: 65px;
  padding: 0 5px;
`;

const SmlSubmitButton = styled(SubmitButton)`
  min-width: 55px;
  padding: 0 5px;
`;

interface Props {
  customerId: string;
}

export default function SubscribersTable({ customerId }: Props) {
  const [editing, setEditing] = useState(false);
  const [editingEmail, setEmail] = useState('');
  const [editingId, setId] = useState(null);
  const [editingMailingIds, setMailingIds] = useState({});

  const setInit = () => {
    setEditing(false);
    setEmail('');
    setId(null);
    setMailingIds({});
  };

  const handleEditClick = (e, subscriber) => {
    const { email, id, mailingIds } = subscriber;
    e.preventDefault();
    setEditing(true);
    setEmail(email);
    setId(id);
    const toggleVals = mailingIds.reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = true;
      return obj;
    }, {});
    // if a user only had Horizon, they get the option to add weekly
    if (!toggleVals[2]) toggleVals[2] = false;
    setMailingIds(toggleVals);
  };

  const handleCancel = e => {
    e.preventDefault();
    setInit();
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setMailingIds({ ...editingMailingIds, [name]: checked });
  };

  const headings = ['Email', 'Reports', ''];

  const renderEmail = (email, id) =>
    editing && id === editingId ? (
      <StyledTextInput type="text" value={editingEmail} onChange={handleEmailChange} />
    ) : (
      <StyledCell>{email}</StyledCell>
    );

  const renderReports = subscriber => {
    const { id, mailingIds } = subscriber;
    return editing && id === editingId ? (
      <>
        {Object.keys(editingMailingIds).map(key => (
          <InputLabel key={key}>
            <Checkbox onChange={handleCheckboxChange} name={key} id={key} checked={editingMailingIds[key]} />
            {EMAIL_REPORT_TYPES[key]}
          </InputLabel>
        ))}
      </>
    ) : (
      <StyledCell>{mailingIdsToText(mailingIds)}</StyledCell>
    );
  };

  const renderEditDeleteButtons = subscriber => {
    // eslint-disable-next-line no-shadow
    const { customerId, email, id, mailingIds } = subscriber;
    // mutation on edit update
    const subscriptionsUpdateOnEdit = (cache, { data: { editSubscription } }) => {
      cache.writeQuery({
        data: { emailSubscriptions: editSubscription },
        query: EmailSubscriptionsQuery,
        // eslint-disable-next-line no-shadow
        variables: { customerId },
      });
    };

    return (
      <Mutation mutation={EditSubscriptionMutation} update={subscriptionsUpdateOnEdit}>
        {(editSubscription, { error, loading }) => {
          if (error) return <P>error</P>;
          if (loading) return <P>Editing...</P>;

          // update subscriptions on delete
          const subscriptionsUpdateOnDelete = (cache, { data: { deleteSubscriptions } }) => {
            cache.writeQuery({
              data: { emailSubscriptions: deleteSubscriptions },
              query: EmailSubscriptionsQuery,
              variables: { customerId },
            });
          };

          return (
            <Mutation mutation={UnsubscribeFromReportsMutation} update={subscriptionsUpdateOnDelete}>
              {/* eslint-disable-next-line no-shadow */}
              {(deleteSubscriptions, { error, loading }) => {
                //  handle delete click
                const handleDelete = async e => {
                  e.preventDefault();
                  const input = {
                    customerId,
                    email,
                    mailIds: mailingIds,
                  };
                  // eslint-disable-next-line no-alert
                  if (window.confirm('Please confirm delete')) {
                    await deleteSubscriptions({ variables: { input } });
                  }
                };

                const handleEditSave = async e => {
                  e.preventDefault();
                  const addSubs = {
                    email: editingEmail,
                    mail_ids: Object.keys(editingMailingIds)
                      .filter(val => editingMailingIds[val])
                      .map(v => parseInt(v, 10)),
                  };
                  if (!addSubs.mail_ids.length) {
                    handleDelete(e);
                    return;
                  }
                  const deleteSubs = {
                    email,
                    mail_ids: mailingIds,
                  };
                  const input = { addSubs, customerId, deleteSubs };
                  await editSubscription({ variables: { input } });
                  setInit();
                };

                if (error) return <P>error</P>;
                if (loading) return <P>Deleting...</P>;

                return (
                  <StyledButtonDiv>
                    <SmlSubmitButton
                      onClick={editing && id === editingId ? handleEditSave : e => handleEditClick(e, subscriber)}
                    >
                      {editing && id === editingId ? 'Save' : 'Edit'}
                    </SmlSubmitButton>
                    {editing && id === editingId ? (
                      <SmlButton onClick={handleCancel}>Cancel</SmlButton>
                    ) : (
                      <SmlAngryButton onClick={handleDelete}>Delete</SmlAngryButton>
                    )}
                  </StyledButtonDiv>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  };

  return (
    <Table dimmed spaced verticalAlign="middle" zebraStripes>
      <Head>
        {headings.map(heading => (
          <Header>{heading}</Header>
        ))}
      </Head>

      <Query query={EmailSubscriptionsQuery} variables={{ customerId }}>
        {({ data, error, loading }) => {
          if (loading)
            return (
              <Row>
                <Cell colSpan="2">
                  <p>Loading...</p>
                </Cell>
              </Row>
            );
          if (error)
            return (
              <Row>
                <Cell colSpan="2">
                  <p>Error</p>
                </Cell>
              </Row>
            );

          const { emailSubscriptions } = data;

          const renderEmailSubscriberRow = subscriber => {
            const { email, id } = subscriber;
            return (
              <Row key={id}>
                <Cell>{renderEmail(email, id)}</Cell>
                <Cell>{renderReports(subscriber)}</Cell>
                <Cell>{renderEditDeleteButtons(subscriber)}</Cell>
              </Row>
            );
          };

          return <Body>{emailSubscriptions.map(subscriber => renderEmailSubscriberRow(subscriber))}</Body>;
        }}
      </Query>
    </Table>
  );
}

// Private functions
const mailingIdsToText = ids =>
  ids.reduce((mailReportsString, id, i, arr) => {
    // eslint-disable-next-line no-param-reassign
    mailReportsString += i < arr.length - 1 ? `${EMAIL_REPORT_TYPES[id]}, ` : `${EMAIL_REPORT_TYPES[id]}`;
    return mailReportsString;
  }, '');
