// @flow

import { loader } from 'graphql.macro';
import hash from 'hash-sum';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { Col } from 'react-flexbox-grid';
import styled, { css } from 'styled-components';

import DeleteUser from './DeleteUser';
import { FetchCustomers } from './List';
import NewParentSubmitButton from './NewParentSubmitButton';
import NewParentDialog from './NewParentDialog';
import NewUserDialog from './NewUserDialog';
import ResetUser from './ResetUser';

import colors from '../../shared/colors';

import Checkbox from '../../shared/ui/atoms/Checkbox';

import NoData from '../../shared/ui/molecules/NoData';
import { Body, Cell, Row, Container as Table } from '../../shared/ui/molecules/Table';

import { GrayButton, SubmitButton } from '../../shared/ui/Buttons';
import ConditionalRender from '../../shared/ui/ConditionalRender';
import Loading from '../../shared/ui/Loading';
import Search from '../../shared/ui/Search';
import TableHeader from '../../shared/ui/TableHeader';
import Title from '../../shared/ui/Title';

export const FetchCustomerUsers = loader('./queries/FetchCustomerUsers.graphql');
export const FetchDeletedCustomerUsers = loader('./queries/FetchDeletedCustomerUsers.graphql');
const UpdateParentMutation = loader('./queries/UpdateUserParent.graphql');

const CheckboxDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  height: 125px;
  justify-content: space-between;
  margin: 0% 15%;
`;

const StyledRestoredContainer = styled.div`
  text-align: left;
`;

const StyledTableTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: center;
  letter-spacing: 0.4px;
  margin: 40px 0 30px 10px;
`;

const StyledMessage = styled.div`
  border: 1px solid ${colors.pureRed};
  color: ${colors.pureRed};
  display: flex;
  justify-content: center;
  padding: 11px 195px;
  text-align: center;

  ${({ success }) =>
    success &&
    css`
      border: 1px solid #9dda37;
      color: #9dda37;
    `};
`;

const defaultCurrentParent = { currentParent: { customerId: '', customerName: '' }, users: [] };

type Props = {
  match: Object,
};

export default function CustomerUserDetail({ match }: Props) {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [parentModalOpen, setParentModalOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(defaultCurrentParent);
  const [success, setSuccess] = useState(false);

  const customerId = match.params.id;

  const { data, error, loading } = useQuery(FetchCustomerUsers, {
    fetchPolicy: 'network-only',
    variables: { customerId },
  });
  const customerUsers = get(data, 'customerUsers', []);
  const hashedCustomerUsers = hash(customerUsers);

  const filteredActiveUsers = useMemo(
    () => sortAndFilterUsers(customerUsers, searchFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hashedCustomerUsers, searchFilter]
  );

  const { data: customersData, loading: customersLoading } = useQuery(FetchCustomers);

  const [updateParent, { loading: updateParentLoading }] = useMutation(UpdateParentMutation, {
    update(cache, { data: newParentData }) {
      const { updateUser } = newParentData;
      updateCache(cache, updateUser, customerId);
    },
  });

  const [
    fetchDeletedUsers,
    { called, data: deletedUsersData, error: deletedUsersError, loading: deletedUsersLoading },
  ] = useLazyQuery(FetchDeletedCustomerUsers, { fetchPolicy: 'network-only', variables: { customerId } });

  const inactiveUsers = get(deletedUsersData, 'deletedCustomerUsers', []);
  const filteredInactiveUsers = useMemo(() => sortAndFilterUsers(inactiveUsers, searchFilter), [
    inactiveUsers,
    searchFilter,
  ]);

  const handleNewParentClick = id => {
    const users = get(data, 'customerUsers', []);
    const { customerName, userId } = users.find(user => user.userId === id);
    setSelectedUsers({ currentParent: { customerId, customerName }, users: [...selectedUsers.users, userId] });
    setParentModalOpen(true);
  };

  const handleHeaderCheckChange = () => {
    if (selectedUsers.users.length !== filteredActiveUsers.length) {
      const ids = filteredActiveUsers.map(({ userId }) => userId);
      setSelectedUsers({ ...selectedUsers, users: ids });
    } else {
      setSelectedUsers({ ...selectedUsers, users: [] });
    }
  };

  const handleCheckboxChange = id => {
    const { users } = selectedUsers;
    if (users.includes(id)) {
      setSelectedUsers({ ...selectedUsers, users: users.filter(userId => userId !== id) });
    } else {
      setSelectedUsers({ ...selectedUsers, users: [...users, id] });
    }
  };

  const cancelNewParentSelection = () => {
    setSelectedUsers(defaultCurrentParent);
    setParentModalOpen(false);
  };

  const handleParentChange = async newId => {
    cancelNewParentSelection();
    const input = { customerId: newId, users: selectedUsers.users };
    try {
      const { data: dataResponse } = await updateParent({ variables: { input } });
      if (dataResponse.failures) {
        const failedEmails = customerUsers.reduce(
          (acc, user) => (dataResponse.failures.includes(user.userId) ? [...acc, user.email] : acc),
          []
        );
        setMessage(`The following users were not assigned to a new parent: ${failedEmails.join(', ')}.`);
        setSuccess(false);
      } else {
        setMessage('User(s) parent was successfully updated');
        setSuccess(true);
      }
    } catch (err) {
      const { response } = err.networkError;
      setMessage(
        response && response.statusText ? response.statusText : 'There was an error setting the user to a new parent.'
      );
      setSuccess(false);
    }
  };

  const handleInviteUser = () => {
    setInviteModalOpen(true);
    setMessage('');
  };

  const renderCustomerDetail = name => <Title title={`${name} Users`} />;

  const setResultMessage = result => {
    setMessage(result.message);
    setSuccess(result.success);
  };

  if (loading || customersLoading || updateParentLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error</p>;
  }

  const customers = get(customersData, 'customers', []);
  const { customerName } = customers.find(customer => customer.customerId === customerId);

  const handleSearchValue = currentValue => {
    if (currentValue !== searchFilter) {
      setSearchFilter(currentValue);
    }
  };

  const renderTable = (title, tableData, type) => (
    <>
      <StyledTableTitle>
        <p>{title}</p>
      </StyledTableTitle>

      <Table data-testid="trace-table" dimmed fixed spaced zebraStripes>
        <TableHeader
          colWidths={['15%', '35%', '45%', '5%']}
          headings={[
            'User Name',
            'Contact',
            'Actions',
            type === 'active' ? (
              <CheckboxDiv>
                <Checkbox
                  checked={selectedUsers.users.length === filteredActiveUsers.length}
                  onChange={handleHeaderCheckChange}
                />
              </CheckboxDiv>
            ) : (
              ''
            ),
          ]}
        />

        <Body>
          <ConditionalRender
            condition={tableData.length > 0}
            fallback={
              <>
                <Row />
                <Row>
                  <Cell colSpan="4">
                    <NoData message={`No data for ${type} users.`} />
                  </Cell>
                </Row>
              </>
            }
          >
            {tableData.map(user => {
              const { email, firstName, lastName, userId } = user;

              return (
                <Row key={userId}>
                  <Cell>
                    {firstName} {lastName}
                  </Cell>
                  <Cell>{email}</Cell>
                  <Cell>
                    <ConditionalRender
                      condition={type === 'active'}
                      fallback={
                        <StyledRestoredContainer>
                          <ResetUser
                            customer={{ customerId, customerName }}
                            resetType="undelete"
                            user={user}
                            setResultMessage={setResultMessage}
                          />
                        </StyledRestoredContainer>
                      }
                    >
                      <div>
                        <ResetUser
                          customer={{ customerId, customerName }}
                          resetType="mfa"
                          user={user}
                          setResultMessage={setResultMessage}
                        />
                        <ResetUser
                          customer={{ customerId, customerName }}
                          resetType="password"
                          user={user}
                          setResultMessage={setResultMessage}
                        />
                        <NewParentSubmitButton
                          handleClick={() => handleNewParentClick(userId)}
                          loading={updateParentLoading}
                        />
                        <DeleteUser called={called} customerId={customerId} userId={userId} />
                      </div>
                    </ConditionalRender>
                  </Cell>
                  <Cell>
                    <ConditionalRender condition={type === 'active'}>
                      <CheckboxDiv>
                        <Checkbox
                          checked={selectedUsers.users.includes(userId)}
                          onChange={() => handleCheckboxChange(userId)}
                        />
                      </CheckboxDiv>
                    </ConditionalRender>
                  </Cell>
                </Row>
              );
            })}
          </ConditionalRender>
        </Body>
      </Table>
    </>
  );

  return (
    <>
      <ConditionalRender
        condition={!!customerUsers.length || !!customers.length}
        fallback={
          <StyledMessage>
            <ConditionalRender condition={!!customerUsers.length} fallback={<p>Error loading customer users</p>}>
              <p>{`No users for customer ${customerName}`}</p>
            </ConditionalRender>
          </StyledMessage>
        }
      >
        <StyledHeader>
          {renderCustomerDetail(customerName)}
          <ConditionalRender condition={customerName === 'Area 1 Demo Customer'}>
            <SubmitButton onClick={handleInviteUser}>Invite User</SubmitButton>
          </ConditionalRender>
        </StyledHeader>

        {message && (
          <StyledMessage success={success}>
            <p>{message}</p>
          </StyledMessage>
        )}
        <ConditionalRender condition={filteredActiveUsers.length > 0 || filteredInactiveUsers.length > 0}>
          <Col u="1-2">
            <Search
              onChange={handleSearchValue}
              searchByPlaceholder="User"
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
          </Col>
        </ConditionalRender>

        {renderTable('Active Users', filteredActiveUsers, 'active')}

        <ConditionalRender condition={filteredInactiveUsers.length > 0} fallback={deletedUsersLoading && <Loading />}>
          <>{renderTable('Deleted Users', filteredInactiveUsers, 'deleted')}</>
        </ConditionalRender>

        <ConditionalRender condition={deletedUsersError}>
          <p>Error fetching inactive users.</p>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={!called}>
        <GrayButton onClick={fetchDeletedUsers}>Fetch Deleted Users</GrayButton>
      </ConditionalRender>
      <ConditionalRender condition={called && !deletedUsersLoading && filteredInactiveUsers.length === 0}>
        <p>No results for deleted users.</p>
      </ConditionalRender>

      <NewParentDialog
        alertOpen={parentModalOpen}
        selectedUsers={selectedUsers}
        handleConfirm={handleParentChange}
        loading={updateParentLoading}
        onActionCancel={cancelNewParentSelection}
      />
      <NewUserDialog
        alertOpen={inviteModalOpen}
        onActionCancel={() => setInviteModalOpen(false)}
        setMessage={setMessage}
        setSuccess={setSuccess}
      />
    </>
  );
}

// delete user from current customer users list
// network only fetch policy will ensure the user shows up in the new parent's list of users
const updateCache = (cache, updatedUsers, customerId) => {
  const { customerUsers } = cache.readQuery({
    query: FetchCustomerUsers,
    variables: { customerId },
  });

  const userIds = updatedUsers.users.map(({ userId }) => userId);
  cache.writeQuery({
    data: { customerUsers: customerUsers.filter(({ userId }) => !userIds.includes(userId)) },
    query: FetchCustomerUsers,
    variables: { customerId },
  });
};

const sortAndFilterUsers = (data, searchTerm = '') => {
  data.sort(sortUsersByLastName);
  if (searchTerm) {
    const processedTerms = searchTerm
      .toLowerCase()
      .trim()
      .split(' ');

    return data.filter(user =>
      processedTerms.some((term, index) => {
        // Unless we're dealing with the first word that a user as typed, skip words
        // that aren't at least three characters long.
        if (index > 0 && term.length < 3) return false;

        return (
          user.firstName.toLowerCase().includes(term) ||
          user.lastName.toLowerCase().includes(term) ||
          user.email.toLowerCase().includes(term)
        );
      })
    );
  }
  return data;
};

const sortUsersByLastName = (userA, userB) => {
  const nameA = userA.lastName.toLowerCase();
  const nameB = userB.lastName.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};
