// @flow

import { useLazyQuery } from 'react-apollo';
import React, { useContext, useEffect } from 'react';

import { get } from 'lodash';
import { CheckAdmin, CurrentUserContext } from './Main';
import SideBarNav from '../../shared/ui/SideBarNav';

export default function SideNav() {
  const { currentUser } = useContext(CurrentUserContext);

  const [getAdminStatus, { data, error, loading }] = useLazyQuery(CheckAdmin, {
    variables: { email: get(currentUser, 'email', '') },
  });

  useEffect(() => {
    if (currentUser) getAdminStatus();
  }, [currentUser]);

  const { isAdmin } = get(data, 'checkAdmin', { isAdmin: false });

  return (
    <SideBarNav style={{ paddingTop: 0 }}>
      <SideBarNav.Header to="/">Home</SideBarNav.Header>
      <SideBarNav.Header>Customers</SideBarNav.Header>
      <SideBarNav.Links>
        <SideBarNav.Link to="/a1s-customers">View All</SideBarNav.Link>
        <SideBarNav.Link to="/a1s-customers/new">Create New</SideBarNav.Link>
        {isAdmin && !error && !loading && <SideBarNav.Link to="/user-admin">Gangplank User Management</SideBarNav.Link>}
      </SideBarNav.Links>
      <SideBarNav.Header to="/logout">Logout</SideBarNav.Header>
    </SideBarNav>
  );
}
