// @flow

import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import LoginLayout from '../../shared/ui/Layouts/Login';
import LoginForm from './Form';
import { CurrentUserContext } from '../app/Main';

const Login = () => {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <Route>
      {({ history, location }) => {
        if (currentUser) history.push(location.state || '/');
        return (
          <LoginLayout>
            <LoginForm />
          </LoginLayout>
        );
      }}
    </Route>
  );
};

export default Login;
