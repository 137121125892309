// @flow

import { loader } from 'graphql.macro';
import { get } from 'lodash';
import { rem } from 'polished';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import styled, { css } from 'styled-components';

import { CurrentUserContext } from '../../app/Main';

import ConditionalRender from '../../../shared/ui/ConditionalRender';
import Loading from '../../../shared/ui/Loading';

import PaddedContent from '../../../shared/ui/atoms/PaddedContent';
import Subtitle from '../../../shared/ui/atoms/Subtitle';
import TextInput from '../../../shared/ui/atoms/TextInput';
import Title from '../../../shared/ui/atoms/Title';

import AlertDialog from '../../../shared/ui/molecules/AlertDialog';
import NoData from '../../../shared/ui/molecules/NoData';

import { FetchCustomers } from '../List';

const DeleteCheckQuery = loader('../queries/FetchCustomerDeleteData.graphql');
const DeleteCustomerMutation = loader('../queries/DeleteCustomer.graphql');

const FlexCol = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `};

  ${({ col2 }) =>
    col2 &&
    css`
      width: 30%;
    `};
`;

const FlexRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

type Props = {
  customerId: string,
  deleteCheckAlertOpen: boolean,
  onActionCancel: Function,
  setDeleteCheckAlertOpen: Function,
  setDeleteSuccessMessage: Function,
};

export default function DeleteCustomerInfoDialog({
  customerId,
  deleteCheckAlertOpen,
  onActionCancel,
  setDeleteCheckAlertOpen,
  setDeleteSuccessMessage,
}: Props) {
  const { currentUser } = useContext(CurrentUserContext);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { data: customersData, loading: customersLoading } = useQuery(FetchCustomers);
  const customer = get(customersData, 'customers', []).filter(c => c.customerId === customerId);
  const { bettermailEnabled = true, customerName = '', dnsRpzEnabled = true, recursiveDnsEnabled = true } =
    customer[0] || {};

  const [
    fetchDeleteData,
    { data: deleteCheckData, error: deleteCheckError, loading: deleteCheckLoading },
  ] = useLazyQuery(DeleteCheckQuery, {
    variables: { customerId },
  });

  useEffect(() => {
    if (customerId) fetchDeleteData();
  }, [customerId]);

  const deleteData = get(deleteCheckData, 'deleteData', {});
  const {
    dnsRequests = '?',
    mailFlowCountLastOneDay = { spam: '?', total: '?' },
    mailFlowCountLastSevenDays = { spam: '?', total: '?' },
    mxConfiguredDomainsCount = '?',
  } = deleteData;

  const [deleteCustomer, { loading: deleteCustomerLoading, error: deleteCustomerError }] = useMutation(
    DeleteCustomerMutation,
    {
      update(cache) {
        setDeleteCheckAlertOpen(false);
        setDeleteSuccessMessage(`${customerName} was successfully deleted.`);
        setDeleteInputValue('');
        updateCacheAfterDelete(cache, customerId);
      },
    }
  );

  useEffect(() => {
    if (deleteCustomerError) {
      setErrorMessage(`There was an error deleting ${customerName}. Please contact Engineering`);
    }
  }, deleteCustomerError);

  const closeInfoDialog = () => {
    setDeleteInputValue('');
    onActionCancel();
  };

  const handleDeleteInput = e => {
    setDeleteInputValue(e.target.value);
  };

  const handleDeleteCustomer = () => {
    if (currentUser && currentUser.userId)
      deleteCustomer({
        variables: {
          input: {
            offboarderId: currentUser.userId,
          },
          customerId,
        },
      });
  };

  return (
    <>
      <AlertDialog
        busy={customersLoading || deleteCheckLoading || deleteCustomerLoading}
        buttonDisabled={deleteInputValue.trim() !== 'DELETE'}
        buttonText={<span>Confirm Delete Customer</span>}
        expanded
        message={
          <ConditionalRender
            condition={
              !customersLoading && customersData && !deleteCheckLoading && deleteCheckData && !deleteCheckError
            }
            fallback={
              customersLoading || deleteCheckLoading ? (
                <Loading />
              ) : (
                <NoData message="No customer information to display. Please contact Engineering." />
              )
            }
          >
            <Title>{`Please Review The Data For ${customerName} Before Deleting`} </Title>
            <PaddedContent pushBottom="md" pushTop="md" style={{ textAlign: 'left' }}>
              <FlexRow>
                <FlexCol>
                  <div>Bettermail Enabled</div>
                  <div>MX Configured Domains Count</div>
                  <div>One Day Mail Flow Counts</div>
                  <div>Seven Day Mail Flow Counts</div>
                  <div>DNS RPZ Enabled</div>
                  <div>Recursive DNS Enabled</div>
                  <div>DNS Requests</div>
                </FlexCol>
                <FlexCol col2>
                  <div>{bettermailEnabled ? 'YES' : 'NO'}</div>
                  <div>{mxConfiguredDomainsCount}</div>
                  <FlexRow>
                    <div>Total: {mailFlowCountLastOneDay.total || 0}</div>
                    <div>Spam: {mailFlowCountLastOneDay.spamCount || 0}</div>
                  </FlexRow>
                  <FlexRow>
                    <div>Total: {mailFlowCountLastSevenDays.total || 0}</div>
                    <div>Spam: {mailFlowCountLastSevenDays.spamCount || 0}</div>
                  </FlexRow>
                  <div>{dnsRpzEnabled ? 'YES' : 'NO'}</div>
                  <div>{recursiveDnsEnabled ? 'YES' : 'NO'}</div>
                  <div>{dnsRequests}</div>
                </FlexCol>
              </FlexRow>

              <DeleteInput deleteInputValue={deleteInputValue} handleDeleteInput={handleDeleteInput} />
            </PaddedContent>
          </ConditionalRender>
        }
        open={deleteCheckAlertOpen}
        onActionCancel={closeInfoDialog}
        onActionConfirm={handleDeleteCustomer}
        wide
      />

      <AlertDialog
        buttonText=""
        cancelText="Close"
        expanded
        message={<Subtitle>{errorMessage}</Subtitle>}
        open={!!errorMessage}
        onActionCancel={() => setErrorMessage('')}
        wide
      />
    </>
  );
}

/*
 *  Private functions and components
 */

const updateCacheAfterDelete = (cache, customerId) => {
  const { customers } = cache.readQuery({ query: FetchCustomers });

  cache.writeQuery({
    data: { customers: customers.filter(customer => customer.customerId !== customerId) },
    query: FetchCustomers,
  });
};

const Text = styled.p`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => rem(theme.spacing.lg)} 0 ${({ theme }) => rem(theme.spacing.sm)};
`;

const InputDiv = styled.div`
  align-items: center;
  display: flex;
  width: 40%;
`;

type DeleteInputProps = {
  deleteInputValue: string,
  handleDeleteInput: Function,
};

const deleteInstructions = "Type 'DELETE' to Confirm";

function DeleteInput({ deleteInputValue, handleDeleteInput }: DeleteInputProps) {
  return (
    <FlexCol center>
      <Text>{deleteInstructions}</Text>
      <InputDiv>
        <TextInput onChange={e => handleDeleteInput(e)} value={deleteInputValue} />
      </InputDiv>
    </FlexCol>
  );
}
