// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Section = styled.div.attrs({ 'data-testid': 'atom-section' })`
  background-color: ${({ alternate, theme }) => (alternate ? theme.colors.ghostWhite : 'transparent')};
  padding: ${({ theme }) => rem(theme.spacing.lg)};

  &:first-child {
    border-radius: ${rem(4)} ${rem(4)} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${rem(4)} ${rem(4)};
  }

  ${({ bordered, theme }) =>
    bordered &&
    css`
      border-bottom: ${theme.colors.platinum} solid ${rem(1)};
    `};

  ${({ zebraStripe }) =>
    !zebraStripe &&
    css`
      background-color: transparent;
    `};
`;

export default Section;
