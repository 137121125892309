// @flow

import { Col, Row } from 'react-flexbox-grid';
import { loader } from 'graphql.macro';
import { Mutation } from 'react-apollo';
import React, { useContext, useState } from 'react';
import { Route } from 'react-router-dom';

import { CurrentUserContext } from '../app/Main';
import { Error as FlashError } from '../../shared/ui/FlashMessages';
import { InputLabel, TextInput, PasswordInput } from '../../shared/ui/Forms';
import ResetPasswordForm from './ResetPasswordForm';
import { SubmitButton } from '../../shared/ui/Buttons';
import validations from '../../modules/models/Validations';

const Login = loader('./queries/LoginMutation.graphql');

const NEW_USER_PASSWORD = 'whysosassy';

export default function LoginForm() {
  const { handleCurrentUser } = useContext(CurrentUserContext);

  const [id, setId] = useState(null);
  const [loginErrors, setLoginErrors] = useState([]);
  const [name, setName] = useState('');
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
  const [user, setUser] = useState({ email: '', password: '' });

  const handleInputChange = e => {
    // eslint-disable-next-line no-shadow
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
    if (loginErrors.length) setLoginErrors([]);
  };

  const setToInitialState = () => {
    setUser({ email: '', password: '' });
    setId(null);
    setShowNewPasswordForm(false);
  };

  const validateFields = () => {
    const { email, password } = user;
    const errors = [];
    if (!validations.presence(email)) errors.push("'Email' can't be blank");
    if (!validations.presence(password)) errors.push("'Password' can't be blank");
    if (errors.length) setLoginErrors(errors);
    return !errors.length;
  };

  return (
    <Route>
      {({ location }) => (
        <Mutation mutation={Login}>
          {(loginUser, { error, loading }) => {
            const handleSubmit = async e => {
              e.preventDefault();
              if (validateFields()) {
                const input = { email: user.email, password: user.password };
                const { data } = await loginUser({ variables: { input } });

                if (!error && !loading && user.password === NEW_USER_PASSWORD) {
                  setId(data.loginUser.userId);
                  setName(data.loginUser.name);
                  setShowNewPasswordForm(true);
                } else if (data && data.loginUser) handleCurrentUser(data.loginUser);
              }
            };

            return (
              <>
                {!showNewPasswordForm ? (
                  <>
                    {location.state && location.state.error && <FlashError>{location.state.error}</FlashError>}
                    <form onSubmit={handleSubmit}>
                      {!!loginErrors.length && loginErrors.map(err => <FlashError>{err}</FlashError>)}
                      {error && <FlashError>Login Error</FlashError>}
                      <Row>
                        <Col lg={12}>
                          <InputLabel>Email:</InputLabel>
                          <TextInput onChange={handleInputChange} name="email" placeholder="email" />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <InputLabel>Password:</InputLabel>
                          <PasswordInput onChange={handleInputChange} name="password" />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          {!loading ? (
                            <SubmitButton style={{ margin: '10px 0px' }} onClick={handleSubmit}>
                              Login
                            </SubmitButton>
                          ) : (
                            <SubmitButton disabled style={{ margin: '10px 0px' }}>
                              Submitting...
                            </SubmitButton>
                          )}
                        </Col>
                      </Row>
                    </form>
                  </>
                ) : (
                  <ResetPasswordForm id={id} name={name} onSuccess={setToInitialState} />
                )}
              </>
            );
          }}
        </Mutation>
      )}
    </Route>
  );
}
