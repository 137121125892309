// @flow

import { get } from 'lodash';
import { Col, Row } from 'react-flexbox-grid';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import styled from 'styled-components';
import check from '../../check.svg';
import { FetchCustomers } from './List';
import Copy from '../../shared/ui/Copy';
import { naicsCodes2017 } from '../../shared/constants';
import Loading from '../../shared/ui/Loading';
import { P } from '../../shared/ui/Typography';
import { errorKeys } from '../../shared/errorKeys';

const FlexedP = styled(P)`
  display: flex;
  margin-bottom: 0;

  & > img {
    padding-left: 5px;
  }

  &:last-child {
    margin-bottom: 15px;
  }
`;

const TooltipP = styled.p`
  text-align: center;
`;

type Props = { history: Object, location: Object };

export default function CustomerShow({ history, location }: Props) {
  // eslint-disable-next-line no-unused-vars
  const [customer, setCustomer] = useState(location.state.customer);

  useEffect(() => {
    if (location.state.customer) {
      history.replace({
        pathname: location.pathname,
        state: {},
      });
    } else
      history.push({
        pathname: '/a1s-customers/new',
        state: { error: 'There is no new customer data. You were re-directed to this page.' },
      });
  }, []);

  const { data, error: getParentsError, loading: getParentsLoading } = useQuery(FetchCustomers);

  if (getParentsLoading) return <Loading />;

  const parents = get(data, 'customers', []).filter(c => c.isParent);

  const {
    accountType,
    adminUser,
    apiEnabled,
    bettermailEnabled,
    brsConfigValues,
    brsEnabled,
    customerName,
    errors = [],
    isPpp,
    naics,
    kubrickName,
    parentId,
  } = customer;

  const { email, role } = adminUser;

  // eslint-disable no-unused-vars
  let brsApiKey;
  let brsApiUsername;
  if (brsConfigValues) {
    [brsApiKey, brsApiUsername] = brsConfigValues;
  }
  const displayBrs = brsEnabled && !!brsApiKey && !!brsApiUsername;

  const getParent = () => {
    if (getParentsError) return 'Error fetching parent';
    const found = parents.find(p => p.customerId === parentId);
    if (found) return found.customerName;
    return 'This customer has no parent account.';
  };

  return (
    <Row>
      <Col lg={12}>
        <Row>
          <Col lg={12}>
            <h3>Successfully Onboarded Customer</h3>
            <P>Please review the below information and save important credentials.</P>
            <P>An email has been sent to {email}</P>
          </Col>
        </Row>
      </Col>
      {!!errors.length && (
        <Col lg={12}>
          <Row>
            <Col lg={12}>
              <h3>The following errors occurred:</h3>
              <ul list-style-type="none">{errors.map(err => renderError(err))}</ul>
            </Col>
          </Row>
          <Row />
        </Col>
      )}
      <Col lg={12}>
        <Row>
          <Col lg={3}>
            <P>Customer Name:</P>
          </Col>
          <Col lg={9}>
            <P>{customerName}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>Portal Account Email:</P>
          </Col>
          <Col lg={9}>
            <P>{email}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>Account Parent:</P>
          </Col>
          <Col lg={9}>
            <P>{getParent()}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>Portal Account Access:</P>
          </Col>
          <Col lg={9}>
            <P>{role}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>BCC mxrecord.io address</P>
          </Col>
          <Col lg={9}>
            <P>{kubrickName}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>Account Type:</P>
          </Col>
          <Col lg={9}>
            <P>{accountType}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>Services Provisioned:</P>
          </Col>
          <Col lg={9}>
            <FlexedP>Email - {bettermailEnabled ? <img src={check} alt="Enabled" /> : 'No'}</FlexedP>
            <FlexedP>Edge Integration - {brsEnabled ? <img src={check} alt="Enabled" /> : 'No'}</FlexedP>
            <FlexedP>API - {apiEnabled ? <img src={check} alt="Enabled" /> : 'No'}</FlexedP>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>Pay-Per-Phish Customer</P>
          </Col>
          <Col lg={9}>
            <P>{isPpp ? 'Yes' : 'No'}</P>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <P>NAICS</P>
          </Col>
          <Col lg={9}>
            <P>{`${naics} ${naicsCodes2017[naics]}`}</P>
          </Col>
        </Row>
        {displayBrs && (
          <Row>
            <Col lg={3}>
              <P>Edge Integrations:</P>
            </Col>
            <Col lg={9}>
              <P style={{ display: 'inline-block', marginBottom: 0 }}>API Key:</P>
              <Copy tooltipPosition="bottom" copyText={brsApiKey}>
                <TooltipP>Copy to clipboard.</TooltipP>
              </Copy>
              <P>{brsApiKey}</P>

              <P style={{ display: 'inline-block', marginBottom: 0 }}>API Username:</P>
              <Copy tooltipPosition="bottom" copyText={brsApiUsername}>
                <TooltipP>Copy to clipboard.</TooltipP>
              </Copy>
              <P>{brsApiUsername}</P>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

/*
  Private function
*/

type ErrorType = {
  [any]: string,
};

const renderError = (err: ErrorType) => {
  // example: {email: 'adminUserCreate'}
  const value = Object.keys(err)[0];

  // db errors (integrity or exceptions) do not have a value key
  return value === 'dbError' ? (
    <li key={err[value]}>{err[value]}</li>
  ) : (
    <li key={err[value]}>{errorKeys(value)[err[value]]}</li>
  );
};
