import styled from 'styled-components';

export const H1 = styled.h1`
  color: #000;
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 48px;
  margin-bottom: 30px;
`;

export const H2 = styled.h2`
  color: rgb(51, 51, 51);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 36px;
  margin-bottom: 30px;
`;

export const H3 = styled.h3`
  color: rgb(51, 51, 51);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 36px;
`;

export const H4 = styled.h4`
  color: rgb(51, 51, 51);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.7px;
  line-height: 28px;
  margin-bottom: 30px;
`;

export const H5 = styled.h5`
  color: rgb(51, 51, 51);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 30px;
`;

export const H6 = styled.h6`
  color: rgb(51, 51, 51);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export const Label = styled.span`
  color: rgb(185, 185, 185);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const P = styled.p`
  color: rgb(51, 51, 51);
  font-family: 'aktiv-grotesk', sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 23px;
  margin-bottom: 15px;
`;

export const Code = styled.span`
  color: rgb(110, 5, 203);
  font-family: 'Andale Mono', serif;
  font-size: inherit;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-bottom: 30px;
`;

export const RedSpan = styled.span`
  color: #ff2c41;
  font-size: 11px;
`;
