// @flow

import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import Content from '../Content';

const StyledContent = styled(Content).attrs({ 'data-testid': 'molecule-no-data' })`
  padding: ${({ theme }) => rem(theme.spacing.lg)} 0;
`;

type Props = { children?: string, message?: string };

const NoData = ({ children, message = 'copy' }: Props) => (
  <StyledContent centered>{`- ${children || message} -`}</StyledContent>
);

export default NoData;
