// @flow

import axios from 'axios';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
import { useLazyQuery } from 'react-apollo';
import React, { createContext, useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';

import apiPaths from '../../modules/api/apiPaths';
import ApplicationLayout from '../../shared/ui/Layouts';
import CustomersIndex from '../customers';
import CustomersNew from '../customers/New';
import Login from '../login';
import logo from '../../logo_blue.svg';
import Logout from '../logout';
import UserAdmin from '../userAdmin';

export const CheckAdmin = loader('../userAdmin/queries/CheckAdminQuery.graphql');

type PrivateRouteProps = {
  component: any,
  isAuthenticated: boolean,
  path: string,
};

type redirectProps = {
  location: Object,
};

const Centered = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

export type CurrentUserType = {
  email: string,
  name: string,
  userId: number,
};

type CurrentUserContextType = {
  authenticated: boolean,
  currentUser: ?CurrentUserType,
  handleCurrentUser: Function,
};

export const CurrentUserContext = createContext<CurrentUserContextType>({
  authenticated: false,
  currentUser: null,
  // eslint-disable-next-line no-unused-vars
  handleCurrentUser: (currentUser: ?CurrentUserType) => null,
});

export default function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [getAdminStatus, { data: isAdminData, error: isAdminError, loading: isAdminLoading }] = useLazyQuery(
    CheckAdmin
  );

  useEffect(() => {
    if (currentUser) getAdminStatus({ variables: { email: get(currentUser, 'email', '') } });
  }, [currentUser]);

  const { isAdmin } = get(isAdminData, 'checkAdmin', { isAdmin: false });

  const handleCurrentUser = (user: ?CurrentUserType, error = undefined) => {
    if (!error && !currentUser) {
      setCurrentUser(user);
    }
    if (error && currentUser) {
      setCurrentUser(undefined);
    }
  };

  // axios is still used here because apollo is infinitely re-fetching
  // redux is not used, however
  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(apiPaths.authUser);
      const user = { ...data.data, userId: data.data.user_id };
      handleCurrentUser(user);
    } catch (error) {
      handleCurrentUser(undefined, error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {loading ? (
        <Centered>
          <img src={logo} className="App-logo" alt="logo" />
        </Centered>
      ) : (
        <CurrentUserContext.Provider
          value={{
            authenticated: !!currentUser,
            currentUser,
            handleCurrentUser,
          }}
        >
          <main>
            <Switch>
              <Route exact path="/login" render={props => <Login {...props} />} />
              <Route exact path="/logout" render={props => <Logout {...props} />} />
              <ApplicationLayout>
                <PrivateRoute exact path="/" component={CustomersNew} isAuthenticated={!!currentUser} />
                <PrivateRoute path="/a1s-customers" component={CustomersIndex} isAuthenticated={!!currentUser} />
                {currentUser && isAdmin && !isAdminError && !isAdminLoading && (
                  <PrivateRoute path="/user-admin" component={UserAdmin} isAuthenticated={!!currentUser} />
                )}
              </ApplicationLayout>
            </Switch>
          </main>
        </CurrentUserContext.Provider>
      )}
    </>
  );
}

/*  --------------------------------------------------------------------------
    Private components
*/

// eslint-disable-next-line no-shadow
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props: redirectProps) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
              error: 'Please login.',
            },
          }}
        />
      )
    }
  />
);
