// @flow

import { loader } from 'graphql.macro';
import { Mutation } from 'react-apollo';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, SubmitButton } from '../../shared/ui/Buttons';
import { InputLabel } from '../../shared/ui/Forms';
import ErrorMessages from '../../shared/ui/ErrorMessages';
import { StyledTextInput } from '../../shared/ui/Search';
import validations from '../../modules/models/Validations';

const CreateNewUser = loader('./queries/CreateUserMutation.graphql');
const GetOnboarders = loader('./queries/GetOnboardersQuery.graphql');

export default function NewUserForm() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [name, setName] = useState('');

  const setToInit = () => {
    setEmail('');
    setErrors([]);
    setFormVisible(false);
    setName('');
  };

  const onAddClick = () => {
    setFormVisible(true);
  };

  const CreateOnboarderCacheUpdate = (cache, { data: { onboarder } }) => {
    const { onboarders } = cache.readQuery({ query: GetOnboarders });
    // eslint-disable-next-line no-shadow
    const { email, id, name } = onboarder;
    cache.writeQuery({
      data: { onboarders: [{ email, id, name, __typename: 'Onboarder' }, ...onboarders] },
      query: GetOnboarders,
    });
  };

  return (
    <>
      {!formVisible && (
        <MarginBottom>
          <FancyButton onClick={onAddClick}>+Add Gangplank User</FancyButton>
        </MarginBottom>
      )}
      {formVisible && (
        <Form>
          <>
            {!!errors.length && (
              <div>
                <ErrorMessages errors={errors}>
                  <p style={{ marginBottom: 10 }}>Please see errors below...</p>
                </ErrorMessages>
              </div>
            )}
            <Container>
              <MarginedLabel> Name</MarginedLabel>
              <StyledTextInput
                type="text"
                onChange={e => setName(e.target.value)}
                placeholder="star phish"
                value={name}
              />
              <MarginedLabel> Email</MarginedLabel>
              <StyledTextInput
                type="text"
                onChange={e => setEmail(e.target.value)}
                placeholder="star.phish@area1.com"
                value={email}
              />
            </Container>
            <Container>
              <Mutation mutation={CreateNewUser} update={CreateOnboarderCacheUpdate}>
                {(onboarder, { error, loading }) => {
                  // handle add gangplank user
                  const handleAddUser = async e => {
                    e.preventDefault();
                    const validationErrors = validate(name, email);
                    if (validationErrors.length) setErrors(validationErrors);
                    else {
                      const input = { email, name };
                      await onboarder({ variables: { input } });
                      if (!error && !loading) setToInit();
                    }
                  };

                  if (loading) return <p>Loading...</p>;
                  if (error) return <p>error</p>;
                  return (
                    <>
                      <FancyButton disabled={loading} onClick={handleAddUser}>
                        +Save Onboarder
                      </FancyButton>
                      <CancelButton onClick={() => setToInit()}>Cancel</CancelButton>
                    </>
                  );
                }}
              </Mutation>
            </Container>
          </>
        </Form>
      )}
    </>
  );
}
/*
  Private functions
*/

const validate = (name, email) => {
  const errors = [];
  if (!validations.presence(name)) errors.push('Name cannot be blank');
  if (!validations.presence(email)) errors.push('Email cannot be blank');
  if (!validations.email(email)) errors.push('Email is incorrectly formated');
  return errors;
};

/*
  Private styles
*/

const CancelButton = styled(Button)`
  width: 200px;
`;

const Container = styled.section`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const FancyButton = styled(SubmitButton)`
  width: 200px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  margin-bottom: 30px;
`;

const MarginBottom = styled.section`
  margin-bottom: 30px;
`;

const MarginedLabel = styled(InputLabel)`
  margin-left: 10px;
`;
