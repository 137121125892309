// @flow

import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

// $FlowFixMe
import { ReactComponent } from './info.svg';

const Info = styled(ReactComponent).attrs({ alt: 'Information Icon' })`
  bottom: 0;
  cursor: help;
  height: ${rem(14)};
  width: ${rem(14)};

  &:hover path {
    fill: ${({ theme }) => theme.colors.black} !important;
  }
`;

const Target = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Icon = (props: any, ref) => (
  <>
    <Info />
    <Target ref={ref} {...props} />
  </>
);

// $FlowFixMe
export default React.forwardRef(Icon);
