// @flow

import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';

import type { AccountTypeType } from './Form';
import DropdownFilter from '../../shared/ui/DropdownMenu';

const ACCOUNT_TYPES: Array<AccountTypeType> = ['POV', 'Advantage', 'Enterprise', 'Internal', 'Partner', 'Parent'];

const setAccountTypeMutation = loader('./queries/setAccountTypeMutation.graphql');

type Props = {
  customerType?: AccountTypeType, // this will be null if not set yet
  hideEveryoneElse: (id: ?number) => void,
  id: number,
  small: boolean,
};

// eslint-disable-next-line no-unused-vars
export default function CustomerTypeDropdown({ customerType, hideEveryoneElse, id, small }: Props) {
  const [accountType, setAccountTypeState] = useState(customerType || 'Select Account Type');

  return (
    <Mutation mutation={setAccountTypeMutation}>
      {(setAccountType, { error, loading }) => {
        const handleAccountTypeSelection = async (value: AccountTypeType) => {
          setAccountTypeState(value);
          hideEveryoneElse(null);
          await setAccountType({ variables: { input: { accountType: value }, id } });
        };

        if (error) return <p>Server Error</p>;
        if (loading) return <p>... Loading</p>;

        return (
          <DropdownFilter
            handleChange={handleAccountTypeSelection}
            hideEveryoneElse={hideEveryoneElse}
            id={id}
            options={ACCOUNT_TYPES}
            selected={accountType}
            small
          />
        );
      }}
    </Mutation>
  );
}
