// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Col = styled.col`
  width: ${({ width }) => (typeof width === 'string' ? width : rem(width))};
`;

export default Col;
