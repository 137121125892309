/**
 * Icon Subcomponent for Info
 *
 * Render the "i" icon for the Info component.
 */

import styled from 'styled-components';

import src from './icon_copy.svg';

const Icon = styled.img.attrs({ alt: 'Copy Icon', src })`
  height: 15px;
  cursor: copy;
  width: 15px;
`;

export default Icon;
