/**
 * Info Component
 *
 * Displays an "i" icon that, when hovered over shows a tooltip with more information.
 */

import React, { PureComponent, type Node } from 'react';

import Tooltip from '../Tooltip';

import Container from './Container';
import Icon from './Icon';

type Props = {
  children?: Node,
  tooltipPosition?: 'bottom' | 'left' | 'right' | 'top',
};

type State = { anchor: ?HTMLElement, tooltipShown: boolean };

class Info extends PureComponent<Props, State> {
  state = { anchor: null, tooltipShown: false };

  hideTooltip = () => this.setState({ tooltipShown: false });

  showTooltip = () => this.setState({ tooltipShown: true });

  render() {
    const { children, tooltipPosition = 'bottom' } = this.props;
    const { anchor, tooltipShown } = this.state;

    return (
      <Container>
        <Icon
          onBlur={this.hideTooltip}
          onFocus={this.showTooltip}
          onMouseOut={this.hideTooltip}
          onMouseOver={this.showTooltip}
          ref={ref => this.setState({ anchor: ref })}
        />
        <Tooltip anchor={anchor} position={tooltipPosition} visible={tooltipShown}>
          {children}
        </Tooltip>
      </Container>
    );
  }
}

export default Info;
