// @flow

import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '../Buttons';

import { PAGE_SIZE } from '../../constants';

const StyledUl = styled.ul`
  list-style: none;
  display: inline-flex;
  padding: 0px;
`;

// transform: translateY(0px) -> doesn't work
const StyledButton = styled(Button)`
  min-width: 37px;
  transform: translateY(0px);
  margin-left: -1px;
  &:hover {
    background-color: #fafafa;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f4f4f4;
    `};
`;

type Props = {
  customers: Array<Object>,
  onChangePage: Function,
  pageSize: number,
  currentPage: number,
};

export default function Pagination({
  customers = [],
  onChangePage = () => null,
  pageSize = PAGE_SIZE,
  currentPage = 1,
}: Props) {
  const setPage = page => {
    // eslint-disable-next-line no-use-before-define
    const pagerBar = getPager(customers.length, page, pageSize);
    return pagerBar;
  };
  const pagerBar = setPage(currentPage);
  if (!pagerBar.pages || pagerBar.pages.length <= 1) {
    // don't display pagerBar if there is only 1 page
    return null;
  }

  // eslint-disable-next-line no-shadow
  function getPager(totalCustomers, currentPage = 1, pageSize) {
    const totalPages = Math.ceil(totalCustomers / pageSize);

    let start;
    let end;

    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else if (currentPage <= 6) {
      start = 1;
      end = 10;
    } else if (currentPage + 4 >= totalPages) {
      start = totalPages - 9;
      end = totalPages;
    } else {
      start = currentPage - 5;
      end = currentPage + 4;
    }

    const pages = [...Array(end + 1 - start).keys()].map(i => start + i);

    return {
      totalPages,
      pages,
    };
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <StyledUl>
        <li>
          <StyledButton small onClick={() => onChangePage(1)}>
            First
          </StyledButton>
        </li>
        {pagerBar.pages.map(page => (
          <li key={page}>
            <StyledButton small active={currentPage === page} onClick={() => onChangePage(page)}>
              {page}
            </StyledButton>
          </li>
        ))}
        <li>
          <StyledButton small onClick={() => onChangePage(pagerBar.totalPages)}>
            Last
          </StyledButton>
        </li>
      </StyledUl>
    </div>
  );
}
