// @flow

import { Mutation } from 'react-apollo';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, SubmitButton } from '../../shared/ui/Buttons';
import { Checkbox, InputLabel } from '../../shared/ui/Forms';
import { EmailSubscriptionsQuery, SubscribeToReportMutation, validated } from './dataAndQueries';
import ErrorMessages from '../../shared/ui/ErrorMessages';
import { StyledTextInput } from '../../shared/ui/Search';

type Props = {
  customerId: string,
};

const CancelButton = styled(Button)`
  width: 200px;
`;

const Container = styled.section`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const FancyButton = styled(SubmitButton)`
  width: 200px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  margin-bottom: 30px;
`;

const MarginBottom = styled.section`
  margin-bottom: 30px;
`;

const MarginedLabel = styled(InputLabel)`
  margin-left: 10px;
`;

//  Subsctiptions Edit Form Component
export default function SubscriptionsEditForm({ customerId }: Props) {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [subcribeToVal, setSubscribeToVal] = useState(true);

  const setToInit = () => {
    setEmail('');
    setErrors([]);
    setSubscribeToVal(true);
  };

  const onEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleCheckboxChange = e => {
    setSubscribeToVal(e.target.checked);
  };

  const onAddClick = e => {
    e.preventDefault();
    setFormVisible(true);
  };

  const handleCancel = () => {
    setFormVisible(false);
    setToInit();
  };

  // $FlowFixMe
  const subscriptionsUpdateOnAdd = (cache, { data: { addSubscriptions } }) => {
    cache.writeQuery({
      data: { emailSubscriptions: addSubscriptions },
      query: EmailSubscriptionsQuery,
      variables: { customerId },
    });
  };

  return (
    <>
      {!formVisible && (
        <MarginBottom>
          <FancyButton onClick={onAddClick}>+Add Subcriber</FancyButton>
        </MarginBottom>
      )}
      {formVisible && (
        <Form>
          <>
            {!!errors.length && (
              <div>
                <ErrorMessages errors={errors}>
                  <p style={{ marginBottom: 10 }}>Please see errors below...</p>
                </ErrorMessages>
              </div>
            )}
            <Container>
              <MarginedLabel> Email</MarginedLabel>
              <StyledTextInput type="text" onChange={onEmailChange} placeholder="star.phish@area1.com" value={email} />
              <InputLabel>
                <Checkbox onChange={handleCheckboxChange} name="email" id="email" checked={subcribeToVal} />
                Weekly Phishing Report
              </InputLabel>
            </Container>
            <Container>
              <Mutation mutation={SubscribeToReportMutation} update={subscriptionsUpdateOnAdd}>
                {(addSubscriptions, { error, loading }) => {
                  //  handle add subscriber
                  const handleAdd = async e => {
                    e.preventDefault();
                    if (!Object.keys(validated(email, subcribeToVal)).length) {
                      setErrors([]);
                      const input = { customerId, email, mail_ids: [2] };
                      await addSubscriptions({ variables: { input } });
                      handleCancel();
                    } else setErrors(validated(email, subcribeToVal));
                  };

                  if (loading) return <p>Loading...</p>;
                  if (error) return <p>error</p>;
                  return (
                    <>
                      <FancyButton disabled={loading} onClick={handleAdd}>
                        +Save Subcriber
                      </FancyButton>
                      <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                    </>
                  );
                }}
              </Mutation>
            </Container>
          </>
        </Form>
      )}
    </>
  );
}
