// @flow

/**
 * Content Subcoment for Tooltip
 *
 * Displays the given children in the tooltip with enought room for the tooltip's arrow.
 */

import React, { type Node, useState } from 'react';
import styled, { css } from 'styled-components';

type Props = { children?: Node, className: string };

function Content({ children, className }: Props) {
  const [x, setX] = useState(0);

  const adjustPosition = ref => {
    if (!ref) return;

    const rect = ref.getBoundingClientRect();

    // 15px away from the edge + compensation for 10px padding
    if (rect.right > window.innerWidth - 25) {
      setX((0 - (rect.right - window.innerWidth - 25)) / 2);
    }
  };

  return (
    <div className={className} ref={adjustPosition} style={{ transform: `translate(${x}px, ${0}px)` }}>
      {children}
    </div>
  );
}

export default styled(Content)`
  background-color: #f9f9f9;
  border: #c2c2c2 solid 1px;
  border-radius: 2px;
  padding: 10px;
  position: relative;
  z-index: 1;

  ${props =>
    props.position === 'bottom' &&
    css`
      margin-top: 10px;
    `};
  ${props =>
    props.position === 'left' &&
    css`
      margin-right: 10px;
    `};
  ${props =>
    props.position === 'right' &&
    css`
      margin-left: 10px;
    `};
  ${props =>
    props.position === 'top' &&
    css`
      margin-bottom: 10px;
    `};
`;
