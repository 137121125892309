// @flow

import React from 'react';

import EmailAddForm from './EmailAddForm';
import { getCustomerById } from './dataAndQueries';
import { H1 } from '../../shared/ui/Typography';
import SubscribersTable from './Table';

type Props = {
  match: Object,
};

export default function EmailSubscriptions({ match }: Props) {
  const customerId = match.params.id;
  const customer = getCustomerById(customerId);
  const { customerName } = customer;
  return (
    <div>
      <H1>Email Subscribers for {customerName}</H1>
      <EmailAddForm customerId={customerId} />
      <SubscribersTable customerId={customerId} />
    </div>
  );
}
