// @flow

import React from 'react';
import styled from 'styled-components';
import { P } from '../Typography';

const BaseDiv = styled.div`
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  padding: 5px 10px;
  margin-bottom: 15px;
  width: 100%;
`;

const BaseP = styled(P)`
  margin-bottom: 0;
`;

const ErrorDiv = styled(BaseDiv)`
  border-color: #fc7680;
`;

const ErrorP = styled(BaseP)`
  color: #fc7680;
`;

const NoticeDiv = styled(BaseDiv)`
  border-color: #006da5;
`;

const NoticeP = styled(BaseP)`
  color: #006da5;
`;

type Props = { children?: any };

export function Error({ children }: Props) {
  return (
    <ErrorDiv>
      <ErrorP>{children}</ErrorP>
    </ErrorDiv>
  );
}

export function Notice({ children }: Props) {
  return (
    <NoticeDiv>
      <NoticeP>{children}</NoticeP>
    </NoticeDiv>
  );
}
