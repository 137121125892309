// @flow

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CustomerUserDetail from './CustomerUserDetail';
import CustomersList from './List';
import CustomersNew from './New';
import CustomerShow from './Show';
import EmailSubscriptions from '../emailSubscriptions';

export default function CustomerIndex() {
  return (
    <Switch>
      <Route exact path="/a1s-customers/show/:id" component={CustomerShow} />
      <Route exact path="/a1s-customers" component={CustomersList} />
      <Route exact path="/a1s-customers/new" component={CustomersNew} />
      <Route exact path="/a1s-customers/:id" component={CustomerUserDetail} />
      <Route exact path="/a1s-customers/emails/:id" component={EmailSubscriptions} />
    </Switch>
  );
}
