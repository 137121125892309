/**
 * Links Subcomponent for SideBarNav Component
 */

import styled from 'styled-components';

const Links = styled.ul`
  border-top: #cfcfcf 1px solid;
  list-style: none;
  padding-left: 30px;
  padding-top: 20px;
`;

export default Links;
