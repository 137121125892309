// @flow

import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-flexbox-grid';
import logo from '../../../logo_blue.svg';
import SideNav from '../../../containers/app/SideNav';
import { Error as FlashError } from '../FlashMessages';

const StyledGrid = styled(Grid)`
  background-color: #fff;
  width: 85%;
`;

const HeaderRow = styled(Row)`
  background-color: rgb(68, 185, 248);
  padding: 25px 0;
`;

const PaddedRow = styled(Row)`
  padding: 25px;
`;

const StyledH1 = styled.h1`
  color: #fff;
  font-size: 12px;
  letter-spacing: 2.85px;
  text-transform: uppercase;
`;

type Props = {
  children: Array<any>,
};

export default function ApplicationLayout({ children }: Props) {
  return (
    <Route>
      {({ location }) => (
        <StyledGrid>
          <HeaderRow>
            <Col lg={12}>
              <img src={logo} className="App-logo" alt="logo" />
            </Col>
            <Col lg={12}>
              <StyledH1>Gangplank</StyledH1>
            </Col>
          </HeaderRow>
          <PaddedRow>
            <Col sm={6} md={4} lg={2} style={{ display: 'flex' }}>
              <SideNav />
            </Col>
            <Col sm={6} md={8} lg={10}>
              <Row>
                {location
                  ? // eslint-disable-next-line prettier/prettier
                    location.state &&
                    // eslint-disable-next-line prettier/prettier
                    location.state.error && (
                      // eslint-disable-next-line prettier/prettier
                      <Col lg={12}>
                        {/* eslint-disable-next-line prettier/prettier */}
                        <FlashError>{location.state.error}</FlashError>
                      </Col>
                    )
                  : null}
              </Row>
              {children}
            </Col>
          </PaddedRow>
        </StyledGrid>
      )}
    </Route>
  );
}
