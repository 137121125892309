// @flow

import gql from 'graphql-tag';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Mutation, Query } from 'react-apollo';

import apiPaths from '../../modules/api/apiPaths';
import { CurrentUserContext } from '../app/Main';
import Loading from '../../shared/ui/Loading';
import { SubmitButton } from '../../shared/ui/Buttons';
import { FetchCustomerUsers, FetchDeletedCustomerUsers } from './CustomerUserDetail';
import ConditionalRender from '../../shared/ui/ConditionalRender';

const StyledCell = styled.span`
  padding: 18px;
`;

interface Props {
  customer: {
    customerId: string,
    customerName: string,
  };
  resetType: 'a1sAdmin' | 'mfa' | 'password' | 'undelete';
  user: null;
  setResultMessage: ({ message: string, success: boolean }) => void;
}

const resultMessages = {
  success: { message: 'Your action was successful', success: true },
  fail: { message: 'Error: your action was not successful', success: false },
};

// Renders the buttons used to set a1s admin as a customer user, reset pw, undelete user, and reset 2FA
// The component first gets all of the customer users if not already in the InMemoryCache
// It then uses a mutation to make the changes
export default function ResetUser({ customer, resetType, user = null, setResultMessage }: Props) {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <Query query={FetchCustomerUsers} variables={{ customerId: customer.customerId }}>
      {({ data, loading }) => {
        const mutation = gql`
          mutation ResetUser($input: ResetUserInput!) {
            resetUser(input: $input) @rest(method: "POST", path: "${apiPaths.resetUser}", type: "User") {
              customerId
              customerName
              deletedAt
              email
              firstName
              lastName
              role
              userId
            }
          }
        `;

        const update = (cache, { data: { resetUser } }) => {
          // usersList only needs to be updated when a new user is added vs properties changed
          if (resetType === 'a1sAdmin' || resetType === 'undelete') {
            const { customerUsers } = cache.readQuery({
              query: FetchCustomerUsers,
              variables: { customerId: customer.customerId },
            });

            cache.writeQuery({
              // check this data for resetType === 'a1sAdmin'
              data: { customerUsers: resetType === 'undelete' ? resetUser : [resetUser, ...customerUsers] },
              query: FetchCustomerUsers,
              variables: { customerId: customer.customerId },
            });

            // update deleted users table
            const { deletedCustomerUsers } = cache.readQuery({
              query: FetchDeletedCustomerUsers,
              variables: { customerId: customer.customerId },
            });

            const removedUsers = customerUsers
              ? resetUser.filter(addedUser => !customerUsers.some(u => addedUser.email === u.email))
              : resetUser;
            const deleted = deletedCustomerUsers
              ? deletedCustomerUsers.filter(delUser => !removedUsers.some(s => s.email === delUser.email))
              : resetUser;

            cache.writeQuery({
              data: { deletedCustomerUsers: deleted },
              query: FetchDeletedCustomerUsers,
              variables: { customerId: customer.customerId },
            });
          }
        };

        const setButtonName = () => {
          switch (resetType) {
            case 'a1sAdmin':
              return 'Add Me to Admin Users';
            case 'mfa':
              return 'Reset 2FA';
            case 'password':
              return 'Reset Password';
            case 'undelete':
              return 'Restore User';
            default:
              return '';
          }
        };

        const setMutationPayload = email => {
          const userPayload = { ...user, deletedAt: undefined };
          const pwObj = { passwordHash: '', otpSecret: '111111' };
          switch (resetType) {
            case 'a1sAdmin':
              return {
                onboarderInfo: { ...currentUser, email, ...pwObj },
                operations: { a1sAdmin: { ...customer } },
              };
            case 'mfa':
            case 'password':
              return {
                onboarderInfo: { ...currentUser },
                operations: { [resetType]: { ...userPayload } },
              };
            case 'undelete':
              return {
                onboarderInfo: { ...currentUser },
                operations: { undelete: { ...userPayload, ...pwObj } },
              };
            default:
              return {};
          }
        };

        return (
          <Mutation mutation={mutation} update={update}>
            {(resetUser, { error, loading: resetUserLoading }) => {
              if (error) return <p>Error</p>;
              const buttonName = setButtonName();
              async function handleClick() {
                const email = currentUser ? currentUser.email : '';
                const newEmail = taggedEmail(email, customer.customerName);
                // For set as admin, see if there is already a user for that customer with that email and, if so, return early.
                if (resetType === 'a1sAdmin' && data.users.findIndex(u => u.email === newEmail) !== -1) {
                  if (!data.users.find(u => u.email === newEmail).deletedAt) {
                    setResultMessage({ message: 'You are already an admin of this user', success: false });
                    return;
                  }
                }
                const input = setMutationPayload(newEmail);

                // If you need the new user its available under `resetUser`.
                await resetUser({ variables: { input } });
                setResultMessage(error ? resultMessages.fail : resultMessages.success);
              }

              return (
                <ConditionalRender
                  condition={resetUserLoading && buttonName === 'Restore User'}
                  fallback={
                    <SubmitButton disabled={loading || resetUserLoading} onClick={handleClick} small>
                      {buttonName}
                    </SubmitButton>
                  }
                >
                  <StyledCell>
                    <Loading small />
                  </StyledCell>
                </ConditionalRender>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
}

// Takes an email and creates "tagged email" with a given tag.
// example: taggedEmail('test@example.com', 'My Tag') ⟶ test+mytag@example.com
function taggedEmail(email: string, tag: string) {
  const normalized = tag
    .split(' ')
    .join('')
    .toLowerCase();
  const parts = email.split('@');
  return `${parts[0]}+${normalized}@${parts[1]}`;
}
