// @flow

import { rem, rgba } from 'polished';
import styled from 'styled-components';

const Container = styled.div.attrs({ role: 'tooltip' })`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  color: ${({ theme }) => theme.colors.lightSlateGray} !important;
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};
  font-size: ${rem(12)};
  left: 0;
  padding: ${rem(4)} ${rem(8)};
  position: absolute;
  top: 0;
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc},
    transform 150ms ${({ theme }) => theme.timing.easeOutBack};
  width: fit-content;
  word-break: break-all;
  z-index: 999;

  &:after {
    border: ${rem(7)} solid transparent;
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
  }

  &[aria-hidden='true'] {
    opacity: 0;
  }
`;

export default Container;
