// @flow

import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import ConditionalRender from '../../../shared/ui/ConditionalRender';
import Loading from '../../../shared/ui/Loading';

import PaddedContent from '../../../shared/ui/atoms/PaddedContent';
import Subtitle from '../../../shared/ui/atoms/Subtitle';

import AlertDialog from '../../../shared/ui/molecules/AlertDialog';

import Dropdown from '../../../shared/ui/organisms/Dropdown';

import { FetchCustomers, sortCustomers } from '../List';

type Props = {
  alertOpen: boolean,
  handleConfirm: Function,
  loading: boolean,
  onActionCancel: Function,
  selectedUsers: {
    currentParent: {
      customerId: string,
      customerName: string,
    },
    users: string[],
  },
};

export default function NewParentDialog({ alertOpen, handleConfirm, loading, onActionCancel, selectedUsers }: Props) {
  const [selected, setSelected] = useState(null);

  const { data, loading: customersLoading } = useQuery(FetchCustomers);
  const {
    currentParent: { customerName },
  } = selectedUsers;

  useEffect(() => {
    if (data && selected !== customerName) setSelected(customerName);
  }, [customerName, setSelected]);

  const customers = sortCustomers(get(data, 'customers', []));
  const customerNames = customers.map(customer => customer.customerName);

  const handleSetNewParent = () => {
    const { customerId } = customers.find(customer => customer.customerName === selected) || { customerId: '' };
    handleConfirm(customerId);
  };

  return (
    <AlertDialog
      busy={loading || customersLoading}
      buttonText={<span>Confirm User Reassignment</span>}
      expanded
      message={
        <>
          <Subtitle>Select Customer for User Reassignment</Subtitle>
          <PaddedContent pushBottom="md" pushTop="md" style={{ textAlign: 'left' }}>
            <ConditionalRender condition={!!customerNames.length && !customersLoading} fallback={<Loading />}>
              <Dropdown
                dataTestId="new-parent-dropdown"
                onOptionSelected={parent => setSelected(parent)}
                options={customerNames}
                renderAsOption={option => option}
              >
                {selected}
              </Dropdown>
            </ConditionalRender>
          </PaddedContent>
        </>
      }
      open={alertOpen}
      onActionCancel={onActionCancel}
      onActionConfirm={handleSetNewParent}
      wide
    />
  );
}
