/**
 * Define all colors here to be used in inline styles. Try to keep in synch with colors.less
 *
 */
const colors = {
  white: '#ffffff',
  backgroundGray: '#fcfcfc',
  labelGray: '#B9B9B9',
  softGray: '#fbfbfb',
  superLightGray: '#f9f9f9',
  facebookGray: '#f8f8f8',
  headerGray: '#f6f6f6',
  davidGray: '#f1f1f2',
  holdenGray: '#efefef',
  circleGray: '#eaeaea',
  gandalfTheGray: '#e5e5e5',
  pencilGray: '#e2e2e2',
  graphiteGray: '#e1e1e1',
  shadowGray: '#dedede',
  lateGray: '#d8d8d8',
  borderGray: '#d7d7d7',
  cementGray: '#d2d2d2',
  concreteGray: '#d1d1d1',
  midderGray: '#cccccc',
  hamGray: '#cbcbcb',
  textGray: '#c2c2c2',
  midGray: '#b3b3b3',
  swedishGray: '#ababab',
  bestGray: '#a1a1a1',
  textDarkGray: '#9b9b9b',
  gris: '#989898',
  dustyGray: '#979797',
  gray: '#858585',
  modalGray: '#525252',
  lightGray: '#4a4a4a',
  threesGray: '#333333',
  sevensGray: '#777777',
  eightsGray: '#888888',
  reallyDarkGray: '#262626',
  countryGrayish: '#ebe8e5',
  boxGrayish: '#d1d2d4',
  silver: '#c2c0c0',
  saltBoxGrayish: '#6d6c6d',
  black: '#000000',

  textDark: '#231f20',

  evenLighterBlue: '#c5ebff',
  babyBlue: '#c4e9fb',
  chalkyBlue: '#bfe9ff',
  qrBlue: '#b6e5ff',
  lineBlue: '#a7c8ef',
  lighterestBlue: '#a5dfff',
  slightlyLighterBlue: '#9fdeff',
  lightestBlue: '#9eddff',
  lightBlue: '#77c9f6',
  oceanBlue: '#4a90e2',
  blue: '#44b9f8',
  midBlue: '#21abeb',
  anotherBlue: '#1d74c4',
  newBlue: '#1e93fa',
  darkerBlue: '#115fbb',
  reputationBlue: '#0b67b7',
  royalBlue: '#0800ec',
  buttonDarkBlue: '#0183c9',
  skyBlue: '#00e0ff',
  caribbeanBlue: '#00bfff',
  buttonBlue: '#00a4fd',
  blueOnBlue: '#009edf',
  retardBlue: '#009df1',
  darkBlue: '#008bd7',
  eternalBlue: '#0082b8',

  lighterGreen: '#cbff91',
  pastelGreen: '#c7f88c',
  pinGreen: '#b8e986',
  peaGreen: '#a0dc5a',
  tipGreen: '#9dda37',
  lightGreen: '#94ef30',
  circleGreen: '#8ad737',
  brightGreen: '#7fff94',
  green: '#7ed321',
  greener: '#7cc142',
  asparagus: '#72c41f',
  darkishGreen: '#72ab38',
  forestGreen: '#6fb777',
  darkGreen: '#5b9c14',
  seaGreen: '#3ef8ce',
  treeFrogGreen: '#35d950',
  neonGreen: '#35ec53',
  confirmedGreen: '#B7EB81',

  pureRed: '#ff0000',
  red: '#ff6f6f',
  firetruckRed: '#f8002f',
  lineRed: '#febebe',
  radicalRed: '#fe1c61',
  lightRed: '#f35d5d',
  whiteRed: '#ed1c24',
  borderRed: '#9d436a',
  sourceRed: '#d91b5b',
  bloodRed: '#d03446',
  darkRed: '#d0021b',
  venetianRed: '#bb0017',
  nodeRed: '#ad2121',
  carmineRed: '#a90419',
  reputationRed: '#64000c',
  hoverRed: 'rgba(255, 144, 144, 0.44)',

  babyPooYellow: '#ffc400',
  whiteYellow: '#ffb800',
  yellow: '#ffb74d',
  midYellow: '#f8ee61',
  brightYellow: '#f7ee62',
  lightYellow: '#f5a623',
  darkYellow: '#d9ac18',

  redPurple: '#ee2a7b',
  psychedelicPurple: '#e700ff',
  countryPurple: '#bcb9b6',
  whitePurple: '#b29dcb',
  purple: '#a42088',
  darkPurple: '#a33efe',
  brownPurple: '#91291d',
  royalPurple: '#9013fe',
  lightPurple: '#8879aa',
  offPurple: '#784a4a',
  textPurple: '#775ba6',
  destPurple: '#706c71',
  prePurple: '#6e05cb',
  midPurple: '#6d2772',

  orange: '#f47e1f',
  darkOrange: '#c52d29',
  saddleBrown: '#793400',
  strongOrange: '#a96900',
  pfirsich: '#f76b1c',
  orangen: '#fad961',
  peach: '#f9dea2',
  sunkistOrange: '#f98732',

  hotterPink: '#ff2f68',
  hotPink: '#ff1c62',
  darkPink: '#fb2866',
  lightPink: '#e97ab0',
  pink: '#cb003c',

  sleepyCyan: '#7afbf7',
  amateurCyan: '#50e3c2',
  caesian: '#449199',
  summerSky: '#37c3f2',
  darkCyan: '#23bf9c',
  turquoise: '#18d3a9',
  cyan: '#13bf06',
  mallard: '#00cb9e',
  aqua: '#00feff',
  duckEgg: '#00e1ff',
  teal: '#00e1af',

  killChain0: '#ccc',
  killChain1: '#edead9',
  killChain2: '#dbd4b3',
  killChain3: '#dcca76',
  killChain4: '#f9d738',
  killChain5: '#fc7529',
  killChain6: '#d0021b',
  killChain7: '#ab0015',

  killChain0Start: '#ccc',
  killChain0Stop: '#ccc',
  killChain1Start: '#fffada',
  killChain1Stop: '#fff5bd',
  killChain2Start: '#ffee94',
  killChain2Stop: '#ffe483',
  killChain3Start: '#ffdc00',
  killChain3Stop: '#ffd200',
  killChain4Start: '#ffb100',
  killChain4Stop: '#ffa13a',
  killChain5Start: '#ff9d12',
  killChain5Stop: '#ff5c00',
  killChain6Start: '#ff0e4d',
  killChain6Stop: '#ff001f',
  killChain7Start: '#ee002a',
  killChain7Stop: '#ab0015',
};

export default colors;
