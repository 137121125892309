const apiPaths = {
  // these use graphql
  authenticate: '/onboarding/authenticate',
  deleteUser: '/onboarding', // customer/<customerId>/user/<userId>  to delete
  resetUser: '/onboarding/reset', // payload is either {password:user_id} or {mfa:user_id}
  // these use axios
  authUser: '/api/onboarding/user', // currently logged in user
  logout: '/api/onboarding/logout',
};
export default apiPaths;
