// @flow

import React from 'react';
import styled from 'styled-components';

import ErrorMessages from '../ErrorMessages';
import { InputLabelRight } from '.';

const ContainerDiv = styled.div`
  font-size: 80%;
  text-align: right;
`;

type Props = { errors: Array<{ [string]: string }>, inputID?: string };

export default function LabelRightWithErrors({ errors = [], inputID, ...props }: Props) {
  return (
    <ContainerDiv>
      <InputLabelRight errored={errors.length > 0} {...props} />
      <ErrorMessages errors={errors} />
    </ContainerDiv>
  );
}
