// @flow

import React from 'react';

import { SubmitButton } from '../../shared/ui/Buttons';

interface Props {
  handleClick: Function;
  loading: boolean;
}

export default function NewParentSubmitButton({ handleClick, loading }: Props) {
  return (
    <SubmitButton disabled={loading} onClick={handleClick} small>
      Reassign User
    </SubmitButton>
  );
}
