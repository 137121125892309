// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.span`
  display: inline;

  & + & {
    margin-left: ${({ theme }) => rem(theme.spacing.md)};
  }

  ${({ block }) =>
    block &&
    css`
      display: block;
      & + & {
        margin-left: 0px;
      }
    `};
`;

export default Container;
