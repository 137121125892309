// @flow
import { loader } from 'graphql.macro';

import { get } from 'lodash';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';

import CustomerTypeDropdown from './CustomerTypeDropdown';
import DeleteCustomerInfoDialog from './DeleteCustomerInfoDialog';
import ParentReassignmentDialog from './ParentReassignmentDialog';

import { AngryButton, Button, SubmitButton } from '../../shared/ui/Buttons';
import { H1 } from '../../shared/ui/Typography';
import NoData from '../../shared/ui/NoData';
import Pagination from '../../shared/ui/Pagination';
import Search from '../../shared/ui/Search';
import { Body, Cell, Row, Container as Table } from '../../shared/ui/TableNew';
import TableHeader from '../../shared/ui/TableHeader';
import ConditionalRender from '../../shared/ui/ConditionalRender';
import { PAGE_SIZE, RETROSCAN_PARENT_NAME } from '../../shared/constants';
import ConvertToPRADialog from './ConvertToPRADialog';

const FlexRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const MarginBottom = styled.div`
  margin-bottom: 30px;
`;

export const StyledCell = styled.span`
  display: block;
  padding-left: 10px;
  text-align: left;
  white-space: nowrap;
`;
const StyledButton = styled(Button)`
  min-width: 113px !important;
`;
export const StyledButtonDiv = styled.div`
  display: flex;
  text-align: center;
  white-space: nowrap;
`;

const StyledMessage = styled.div`
  border: 1px solid #9dda37;
  color: #9dda37;
  display: flex;
  justify-content: center;
  margin-left: ${rem(100)};
  padding: ${({ theme }) => rem(theme.spacing.sm)} ${({ theme }) => rem(theme.spacing.xxl)};
`;

const StyledErrorMessage = styled.div`
  border: 1px solid red;
  color: red;
  display: flex;
  justify-content: center;
  margin-left: ${rem(50)};
  padding: ${({ theme }) => rem(theme.spacing.sm)} ${({ theme }) => rem(theme.spacing.xxl)};
`;

const ConvertToPRAMutation = loader('./queries/ConvertToPRAMutation.graphql');
export const FetchCustomers = loader('./queries/FetchCustomers.graphql');
const UpdateCustomerParent = loader('./queries/UpdateCustomerParent.graphql');

const defaultCustomer = {
  customerId: '',
  customerName: '',
  parentId: '',
};

type Props = {
  history: Object,
};

export default function CustomersList({ history }: Props) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [convertToPRAErrorMessage, setConvertToPRAErrorMessage] = useState('');
  const [deleteCheckAlertOpen, setDeleteCheckAlertOpen] = useState(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState('');
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [dontHide, setDontHide] = useState(null);
  const [page, setPage] = useState(1);
  const [parentAssignmentModalOpen, setParentAssignmentModalOpen] = useState(false);
  const [parentReassignmentMessage, setParentReassignmentMessage] = useState('');
  const [parentReassignmentCustomer, setParentReassignmentCustomer] = useState(defaultCustomer);
  const [praInfo, setPraInfo] = useState({ parentId: null, customerName: null, customerId: null });
  const [searchFilter, setSearchFilter] = useState('');

  const { data, error, loading } = useQuery(FetchCustomers);
  const customers = get(data, 'customers', []);

  const parentAccounts = get(data, 'customers', []).filter(c => c.accountType === 'Parent');

  const [updateCustomerParent, { loading: updateParentLoading }] = useMutation(UpdateCustomerParent, {
    onCompleted: () => {
      setParentReassignmentMessage('Customer was successfully reassigned to a new parent');
    },
  });

  const [convertToPRA, { loading: convertToPRALoading }] = useMutation(ConvertToPRAMutation, {
    variables: { customerId: praInfo.customerId },
  });

  const handleCustomerParentChange = async (parentId, customerId) => {
    setParentAssignmentModalOpen(false);
    const input = { parentId };
    try {
      await updateCustomerParent({ variables: { input, customerId } });
    } catch (err) {
      setParentReassignmentMessage('There was an error reassigning parent');
    }
    setParentReassignmentCustomer(defaultCustomer);
  };

  const handleSearchValue = currentValue => {
    if (currentValue !== searchFilter) {
      setSearchFilter(currentValue);
      setPage(1);
    }
  };

  const hideEveryoneElse = id => {
    setDontHide(id);
  };

  const onPageClick = newPage => setPage(newPage);

  const filteredCustomerList = (newSearchFilter, customerList) => {
    if (!newSearchFilter.length) return customerList;
    return customerList.filter(item => item.customerName.toLowerCase().indexOf(newSearchFilter.toLowerCase()) !== -1);
  };

  const handleConvertToPraClick = (parentId, customerId, customerName) => {
    setPraInfo({ parentId, customerId, customerName });
    setAlertOpen(true);
  };

  const handleConvertToPRA = async input => {
    setAlertOpen(false);
    try {
      await convertToPRA({ variables: { input } });
    } catch (e) {
      setConvertToPRAErrorMessage('There was na error converting this customer to PRA.');
      window.scroll(0, 0);
    }
  };

  const handleCustomerDeleteCheck = customerId => {
    setParentReassignmentMessage('');
    setDeleteSuccessMessage('');
    setDeleteCheckAlertOpen(true);
    setDeleteCustomerId(customerId);
  };

  const handleParentReassignmentClick = customer => {
    setDeleteSuccessMessage('');
    setParentReassignmentMessage('');
    setParentAssignmentModalOpen(true);
    setParentReassignmentCustomer(customer);
  };

  const cancelNewParentSelection = () => {
    setParentReassignmentMessage('');
    setParentAssignmentModalOpen(false);
    setParentReassignmentCustomer(defaultCustomer);
  };

  const getParentName = parentId => {
    const parent = parentAccounts.find(p => p.customerId === parentId);
    if (parent) return parent.customerName;
    return '';
  };

  const retroParentId = get(parentAccounts.find(p => p.customerName === RETROSCAN_PARENT_NAME), 'customerId', '');

  if (error) return <p>Error</p>;
  const filteredCustomers = filteredCustomerList(searchFilter, sortCustomers(customers));
  const currentPage = filteredCustomers.slice(
    page * PAGE_SIZE - PAGE_SIZE,
    page * PAGE_SIZE > filteredCustomers.length ? filteredCustomers.length : page * PAGE_SIZE
  );

  return (
    <div>
      <Col lg={12}>
        <H1>View All Customers</H1>
      </Col>
      <FlexRow>
        <Col u="1-2">
          <Search onChange={handleSearchValue} searchByPlaceholder="Customer Name" />
        </Col>
        <Col>
          <ConditionalRender condition={!!deleteSuccessMessage || !!parentReassignmentMessage}>
            <StyledMessage>{deleteSuccessMessage || parentReassignmentMessage}</StyledMessage>
          </ConditionalRender>
          <ConditionalRender condition={!!convertToPRAErrorMessage}>
            <StyledErrorMessage>{convertToPRAErrorMessage}</StyledErrorMessage>
          </ConditionalRender>
        </Col>
      </FlexRow>
      <Table data-testid="allowed-senders-table" dimmed fixed spaced verticalAlign="middle" zebraStripes>
        <TableHeader
          colWidths={['16%', '16%', '24%', '14%', '16%', '14%']}
          headings={['Company Name', 'Parent Management', 'User Management', '', 'Account Type', '']}
          padCell={heading => heading !== 'Account Type'}
        />

        <ConditionalRender condition={loading}>
          <Body>
            <Row />
            <Row>
              <Cell colSpan="4">... Loading</Cell>
            </Row>
          </Body>
        </ConditionalRender>

        <ConditionalRender condition={!loading && (!currentPage.length || error)}>
          <Body>
            <Row />
            <Row>
              <Cell colSpan="4">
                <NoData>- There is no data -</NoData>
              </Cell>
            </Row>
          </Body>
        </ConditionalRender>

        <ConditionalRender condition={!loading && !error && !!currentPage.length}>
          <Body>
            {currentPage.map(customer => {
              const { accountType, customerId, customerName, parentId } = customer;
              const hasRetroScanParent = retroParentId === parentId;

              return (
                <Row key={customerId}>
                  <Cell>{customerName}</Cell>
                  <Cell>
                    <ParentReassignmentButton
                      handleClick={() => handleParentReassignmentClick(customer)}
                      loading={updateParentLoading && customer.customerName === parentReassignmentCustomer.customerName}
                      parentName={getParentName(parentId)}
                    />
                  </Cell>
                  <Cell>
                    <StyledButtonDiv>
                      <Button primary small onClick={() => history.push(`/a1s-customers/${customerId}`)}>
                        Manage Users
                      </Button>
                      <Button primary small onClick={() => history.push(`/a1s-customers/emails/${customerId}`)}>
                        Subscriptions
                      </Button>
                    </StyledButtonDiv>
                  </Cell>
                  <Cell>
                    <ConditionalRender condition={hasRetroScanParent}>
                      <StyledButton
                        primary
                        small
                        onClick={() => handleConvertToPraClick(parentId, customerId, customerName)}
                      >
                        Convert to PRA
                      </StyledButton>
                    </ConditionalRender>
                  </Cell>
                  <Cell marginLeft="10px" verticalAlign="middle">
                    {(!dontHide || customerId === dontHide) && (
                      <>
                        {customerName === 'Area 1 Demo Customer' ? (
                          <p>DEMO</p>
                        ) : (
                          <MarginBottom>
                            <CustomerTypeDropdown
                              customerType={accountType}
                              hideEveryoneElse={hideEveryoneElse}
                              id={customerId}
                              small
                            />
                          </MarginBottom>
                        )}
                      </>
                    )}
                  </Cell>
                  <Cell>
                    <StyledButtonDiv>
                      <AngryButton primary small onClick={() => handleCustomerDeleteCheck(customerId)}>
                        Delete Customer
                      </AngryButton>
                    </StyledButtonDiv>
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </ConditionalRender>
      </Table>
      <DeleteCustomerInfoDialog
        customerId={deleteCustomerId}
        deleteCheckAlertOpen={deleteCheckAlertOpen}
        onActionCancel={() => setDeleteCheckAlertOpen(false)}
        setDeleteCheckAlertOpen={setDeleteCheckAlertOpen}
        setDeleteSuccessMessage={setDeleteSuccessMessage}
      />
      <ConditionalRender condition={parentAssignmentModalOpen}>
        <ParentReassignmentDialog
          alertOpen={parentAssignmentModalOpen}
          customer={parentReassignmentCustomer}
          handleConfirm={handleCustomerParentChange}
          loading={updateParentLoading}
          onActionCancel={cancelNewParentSelection}
        />
      </ConditionalRender>
      <ConditionalRender condition={alertOpen}>
        <ConvertToPRADialog
          alertOpen={alertOpen}
          convertToPRALoading={convertToPRALoading}
          customers={customers}
          handleConvertToPRAFunction={handleConvertToPRA}
          onActionCancel={() => setAlertOpen(false)}
          praInfo={praInfo}
        />
      </ConditionalRender>
      <Pagination currentPage={page} customers={filteredCustomers} onChangePage={onPageClick} pageSize={PAGE_SIZE} />
    </div>
  );
}

// sorts customer list by name
export const sortCustomers = (customers: Array<Object>) => {
  customers.sort((a, b) => {
    const x = a.customerName
      .toLowerCase()
      .split(' ')
      .join('');
    const y = b.customerName
      .toLowerCase()
      .split(' ')
      .join('');
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  });
  return customers;
};

interface ReassignButtonProps {
  handleClick: Function;
  loading: boolean;
  parentName: string;
}

function ParentReassignmentButton({ handleClick, loading, parentName }: ReassignButtonProps) {
  return (
    <SubmitButton disabled={loading} onClick={handleClick} small style={{ width: '100%' }}>
      {parentName}
    </SubmitButton>
  );
}
