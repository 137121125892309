// @flow

import gql from 'graphql-tag';
import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import apiPaths from '../../modules/api/apiPaths';
import { AngryButton } from '../../shared/ui/Buttons';
import Loading from '../../shared/ui/Loading';
import { FetchCustomerUsers, FetchDeletedCustomerUsers } from './CustomerUserDetail';
import ConditionalRender from '../../shared/ui/ConditionalRender';

const StyledCell = styled.span`
  padding: 25px;
`;

interface Props {
  called: Boolean;
  customerId: string;
  userId: string;
}

export default function DeleteUser({ called, customerId, userId }: Props) {
  const uri = `${apiPaths.deleteUser}/${customerId}/user/${userId}`;
  const mutation = gql`
    mutation DeleteUser($customerId: String!, $userId: String!) {
      deleteUser(customerId: $customerId, userId: $userId) @rest(method: "DELETE", path: "${uri}", type: "User") {
        customerId
        customerName
        deletedAt
        email
        firstName
        lastName
        role
        userId
      }
    }
  `;

  const update = (cache, { data: { deleteUser } }) => {
    // update active users table
    const { customerUsers } = cache.readQuery({
      query: FetchCustomerUsers,
      variables: { customerId },
    });

    cache.writeQuery({
      data: {
        customerUsers: customerUsers.filter(c => c.deletedAt === 0),
      },
      query: FetchCustomerUsers,
      variables: { customerId },
    });

    // update deleted users table if present in the UI
    if (called) {
      const { deletedCustomerUsers } = cache.readQuery({
        query: FetchDeletedCustomerUsers,
        variables: { customerId },
      });

      cache.writeQuery({
        data: {
          deletedCustomerUsers: deletedCustomerUsers.push(deleteUser),
        },
        query: FetchDeletedCustomerUsers,
        variables: { customerId },
      });
    }
  };

  return (
    <Mutation mutation={mutation} update={update}>
      {(deleteUser, { error, loading }) => {
        if (error) {
          return <p>Error</p>;
        }
        async function handleClick() {
          /* eslint-disable no-alert */
          // eslint-disable-next-line no-restricted-globals
          if (confirm('Are you sure you want to delete this user?')) {
            await deleteUser({ variables: { customerId, userId } });
          }
        }
        /* eslint-disable no-alert */

        return (
          <ConditionalRender
            condition={loading}
            fallback={
              <AngryButton small onClick={handleClick}>
                Delete
              </AngryButton>
            }
          >
            <StyledCell>
              <Loading small />
            </StyledCell>
          </ConditionalRender>
        );
      }}
    </Mutation>
  );
}
