// @flow

import React, { PureComponent, type Node } from 'react';

import Tooltip from '../TooltipTrackingMouse';

import Inner from './Inner';
import Outer from './Outer';

type Props = {
  dataTestId?: string,
  disabled: boolean,
  children?: Node,
  tooltipContents?: Node,
  tooltipScrollNode?: ?HTMLElement,
};
type State = {};

const preventKeyboardBehavior = (event, disabled) => {
  if (disabled) {
    event.stopPropagation();
    event.preventDefault();
  }
};

class DisabledContainer extends PureComponent<Props, State> {
  container: ?HTMLDivElement = null;

  assignRef = (ref: any) => {
    this.container = ref;
  };

  render() {
    const {
      dataTestId = 'atom-disabled-container',
      disabled,
      children,
      tooltipContents,
      tooltipScrollNode,
    } = this.props;

    return (
      <Outer
        data-testid={dataTestId}
        disabled={disabled}
        onKeyDown={event => preventKeyboardBehavior(event, disabled)}
        onKeyPress={event => preventKeyboardBehavior(event, disabled)}
        onKeyUp={event => preventKeyboardBehavior(event, disabled)}
        ref={this.assignRef}
      >
        {tooltipContents && this.container && disabled ? (
          <Tooltip anchor={this.container} scrollNode={tooltipScrollNode} visible>
            {tooltipContents}
          </Tooltip>
        ) : null}
        <Inner disabled={disabled}>{children}</Inner>
      </Outer>
    );
  }
}

export default DisabledContainer;
