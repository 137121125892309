// @flow

/**
 * Info Component
 *
 * Displays an copy icon that, when hovered over shows a tooltip with
 * more information and also copies the text on click
 */

import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from './Icon';
import Tooltip from './Tooltip';

type Props = {
  children: Node,
  className: string,
  copyText: string,
  tooltipPosition: string,
};

function Copy({ children, className, copyText, tooltipPosition }: Props) {
  const [anchorRef, setAnchorRef] = useState({});
  const [copied, setCopied] = useState(false);
  const [tooltipShown, setTooltipShown] = useState(false);

  const handleCopy = e => {
    e.preventDefault();
    copy(copyText);
    setCopied(true);
  };

  return (
    <div className={className}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link style={{ position: 'relative' }} onClick={handleCopy} to="">
        <Icon
          onBlur={() => undefined}
          onMouseOut={() => setTooltipShown(false)}
          onFocus={() => undefined}
          onMouseOver={() => setTooltipShown(true)}
          innerRef={ref => setAnchorRef(ref)}
        />
      </Link>
      <Tooltip anchorRef={anchorRef} position={tooltipPosition} visible={tooltipShown}>
        {copied ? <p style={{ textAlign: 'center' }}>Copied.</p> : children}
      </Tooltip>
    </div>
  );
}

export default styled(Copy)`
  display: inline-block;
  margin-left: 5px;
  position: relative;
  vertical-align: middle;
`;
