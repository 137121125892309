// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Content = styled.article`
  font-size: ${rem(14)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: 400;
    margin: 0 0 ${({ theme }) => rem(theme.spacing.sm)} 0;
    text-transform: uppercase;
  }

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};

  ${({ small }) =>
    small &&
    css`
      font-size: ${rem(12)};

      a {
        font-size: ${rem(12)};
      }
    `};
`;

Content.displayName = 'Content';

export default Content;
