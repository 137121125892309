// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Subtitle = styled.h1.attrs({ 'data-testid': 'atom-subtitle' })`
  color: ${({ theme }) => theme.colors.independence};
  font-size: ${rem(14)};
  font-weight: 400;
  margin: 0;

  ${({ capitalized }) =>
    capitalized &&
    css`
      letter-spacing: ${rem(0.5)};
      text-transform: uppercase;
    `};

  ${({ bordered, theme }) =>
    bordered &&
    css`
      border-bottom: ${theme.colors.antiFlashWhite} solid 1px;
      padding: ${rem(theme.spacing.md)} 0;
    `};
`;

Subtitle.displayName = 'Subtitle';

export default Subtitle;
