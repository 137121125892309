import styled, { css } from 'styled-components';
import colors from '../../colors';

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid #00bfff;
  color: #00bfff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 35px;
  letter-spacing: 0.5px;
  margin: 6px;
  min-width: 110px;
  padding: 0 20px;
  text-align: center;
  outline: none;

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    border: 1px solid ${colors.concreteGray};
    color: ${colors.swedishGray};
  }

  &:hover {
    transition: background-color 0.05s;
    transition: border-color 0.05s;
    transition: color 0.1s;
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      min-width: auto;
    `};
`;

export const SubmitButton = styled(Button)`
  background-color: #00bfff;
  color: #fff;
  margin: 6px;
  padding: 0px 3px;

  &:hover {
    background-color: #1e93fa;
  }
  &:disabled {
    background-color: ${colors.lateGray};
    border: 1px solid ${colors.concreteGray};
    cursor: wait;
  }
`;

export const GrayButton = styled(Button)`
  background-color: rgb(239, 239, 239);
  border: none;
  color: rgb(102, 102, 102);
`;

export const AngryButton = styled(Button)`
  background-color: #fff;
  border-color: ${colors.pureRed};
  color: ${colors.pureRed};

  &:disabled {
    border-color: ${colors.lineRed};
    color: ${colors.lineRed};
  }

  &:hover {
    background-color: #fff;
    border: 1px solid #00bfff;
    color: #00bfff;
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      min-width: auto;
    `};
`;
