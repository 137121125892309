// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import Icon from './Icon';

const Text = styled.span`
  ${Icon} + & {
    margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  }
`;

export default Text;
