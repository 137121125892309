// @flow

import React, { useContext, useState } from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import AlertDialog from '../../../shared/ui/molecules/AlertDialog';
import Subtitle from '../../../shared/ui/atoms/Subtitle';
import PaddedContent from '../../../shared/ui/atoms/PaddedContent';
import Dropdown from '../../../shared/ui/Dropdown';
import TextInput from '../../../shared/ui/atoms/TextInput';
import { InputLabel } from '../../../shared/ui/Forms';

import { CurrentUserContext } from '../../app/Main';

const FlexCol = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  & > label {
    padding: 13px 0px;
  }
  ${({ col1 }) =>
    col1 &&
    css`
      width: 45%;
    `};

  ${({ col2 }) =>
    col2 &&
    css`
      width: 55%;
    `};
`;

const FlexRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: normal;
  width: 100%;
`;

const InputDiv = styled.div`
  align-items: center;
  display: flex;
  padding: 10px;
  width: 100%;
`;

const StyledSubtitle = styled(Subtitle)`
  font-size: ${rem(17)};
  margin: ${rem(15)};
`;

type PRAInfoType = {
  parentId: null,
  customerName: null,
  customerId: null,
};
interface Props {
  alertOpen: boolean;
  convertToPRALoading: boolean;
  customers: Array<any>;
  handleConvertToPRAFunction: Function;
  onActionCancel: Function;
  praInfo: PRAInfoType;
}

export default function ConvertToPRADialog({
  alertOpen,
  convertToPRALoading,
  customers,
  handleConvertToPRAFunction,
  onActionCancel,
  praInfo,
}: Props) {
  const { currentUser } = useContext(CurrentUserContext);

  const [formValues, setFormValues] = useState({
    parentAccount: '',
    newCustomerName: '',
  });
  const [selectedNewParent, setSelectedNewParent] = useState('Cloudflare Retroscan - Parent');

  const parentAccounts = customers.filter(c => c.accountType === 'Parent');
  const newParentUuid = parentAccounts.find(p => p.customerName === selectedNewParent).customerId;

  const handleConvertToPRA = async () => {
    const newParentInfo = {};
    newParentInfo.onboarder_id = currentUser.userId;
    newParentInfo.new_parent = newParentUuid;
    newParentInfo.new_blackbox_name = formValues.newCustomerName;
    newParentInfo.new_kubrick_name = strippedName(formValues.newCustomerName);

    handleConvertToPRAFunction(newParentInfo);
  };

  const handleInputChange = e => {
    e.preventDefault();
    const { value } = e.target;
    setFormValues({ ...formValues, newCustomerName: value });
  };

  return (
    <AlertDialog
      busy={convertToPRALoading}
      buttonText={<span>Convert to PRA</span>}
      buttonDisabled={convertToPRALoading}
      expanded
      message={
        <>
          <StyledSubtitle>Convert {praInfo.customerName} to PRA</StyledSubtitle>
          <PaddedContent>
            <FlexRow>
              <FlexCol col1>
                <InputLabel>Parent Account</InputLabel>
                <InputLabel>New customer name</InputLabel>
              </FlexCol>

              <FlexCol col2>
                <PaddedContent pushBottom="sm" pushTop="md">
                  <Dropdown
                    onOptionSelected={parent => setSelectedNewParent(parent)}
                    options={parentAccounts.map(n => n.customerName)}
                    renderAsOption={parentAccounts.find(n => n.customerName === praInfo.parentName)}
                  >
                    {selectedNewParent}
                  </Dropdown>
                </PaddedContent>
                <InputDiv>
                  <TextInput onChange={e => handleInputChange(e)} />
                </InputDiv>
              </FlexCol>
            </FlexRow>
          </PaddedContent>
        </>
      }
      open={alertOpen}
      onActionCancel={onActionCancel}
      onActionConfirm={handleConvertToPRA}
      wide
    />
  );
}

const strippedName = name =>
  name
    .replace(/[^a-zA-Z0-9_-]/g, '')
    .replace(/\s+/g, '')
    .toLowerCase();
