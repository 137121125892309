// @flow

import React from 'react';
import styled from 'styled-components';

export const TitleStyle = styled.p`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 175%;
  margin-right: 5%;
`;

type Props = { title: string };

export default function Title({ title = '' }: Props) {
  return <TitleStyle>{title}</TitleStyle>;
}
