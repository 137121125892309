// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import styled from 'styled-components';

import Content from '../Content';

const StyledContent = styled(Content)`
  padding: ${({ theme }) => rem(theme.spacing.lg)} 0;
`;

type Props = { children?: Node | string };

const NoData = ({ children }: Props) => <StyledContent centered>{children}</StyledContent>;

export default NoData;
