// @flow

import { useContext } from 'react';
import axios from 'axios';
import apiPaths from '../../modules/api/apiPaths';
import client from '../../apollo';
import { CurrentUserContext } from '../app/Main';

const Logout = () => {
  const { handleCurrentUser } = useContext(CurrentUserContext);
  const logout = async () => {
    try {
      await axios.get(apiPaths.logout);
      window.location = '/login';
    } catch (error) {
      handleCurrentUser(undefined, error);
    }
    client.resetStore();
  };
  logout();
  return false;
};

export default Logout;
