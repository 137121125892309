// @flow

import styled, { css } from 'styled-components';

export default styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      & > * {
        color: ${({ theme }) => theme.colors.lightGray};
      }
    `};
`;
