/**
 * Arrow Subcoment for Tooltip
 */

import styled, { css } from 'styled-components';

import src from './arrow.svg';

const Arrow = styled.img.attrs({ alt: 'Tooltip Arrow', src })`
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
  z-index: 2;

  ${props =>
    props.position === 'bottom' &&
    css`
      left: 50%;
      top: 1px;
      margin-left: -7.5px;
    `};

  ${props =>
    props.position === 'left' &&
    css`
      margin-left: -14px;
      margin-top: -5px;
      transform: rotate(90deg);
      left: 100%;
      top: 50%;
    `};

  ${props =>
    props.position === 'right' &&
    css`
      margin-top: -5px;
      transform: rotate(-90deg);
      top: 50%;
    `};

  ${props =>
    props.position === 'top' &&
    css`
      left: 50%;
      margin-top: -11px;
      margin-left: -7.5px;
      transform: rotate(180deg);
      top: 100%;
    `};
`;

export default Arrow;
