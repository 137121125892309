// @flow

import { loader } from 'graphql.macro';
import { Mutation } from 'react-apollo';
import React from 'react';
import styled from 'styled-components';

import { AngryButton } from '../../shared/ui/Buttons';

const DeleteUser = loader('./queries/DeleteUserMutation.graphql');

const GetOnboarders = loader('./queries/GetOnboardersQuery.graphql');
type Props = { id: string };

const P = styled.p`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px;
`;

const SmlAngryButton = styled(AngryButton)`
  min-width: 65px;
  padding: 0 5px;
`;

export default function DeleteButton({ id }: Props) {
  const cacheUpdateOnDelete = cache => {
    const { onboarders } = cache.readQuery({ query: GetOnboarders });
    cache.writeQuery({
      data: { onboarders: onboarders.filter(e => e.id !== id) },
      query: GetOnboarders,
    });
  };

  return (
    <Mutation mutation={DeleteUser} update={cacheUpdateOnDelete}>
      {(deleteUser, { error, loading }) => {
        //  handle delete click
        const handleDelete = async e => {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (window.confirm('Please confirm delete')) await deleteUser({ variables: { id } });
        };

        if (error) return <P>error</P>;
        if (loading) return <P>Deleting...</P>;

        return <SmlAngryButton onClick={handleDelete}>Delete</SmlAngryButton>;
      }}
    </Mutation>
  );
}
