import React, { type Node } from 'react';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-flexbox-grid';
import logo from '../../../logo_blue.svg';

const StyledGrid = styled(Grid)`
  background-color: #fff;
`;

const HeaderRow = styled(Row)`
  background-color: rgb(68, 185, 248);
  padding: 25px 0;
`;

const PaddedRow = styled(Row)`
  padding: 25px;
`;

const StyledH1 = styled.h1`
  color: #fff;
  font-size: 12px;
  letter-spacing: 2.85px;
  text-transform: uppercase;
`;

type Props = {
  children: Node,
};

export default function LoginLayout({ children }: Props) {
  return (
    <StyledGrid style={{ width: 500 }}>
      <HeaderRow>
        <Col lg={12}>
          <img src={logo} className="App-logo" alt="logo" />
        </Col>
        <Col lg={12}>
          <StyledH1>Gangplank</StyledH1>
        </Col>
      </HeaderRow>
      <PaddedRow>
        <Col lg={12}>{children}</Col>
      </PaddedRow>
    </StyledGrid>
  );
}
