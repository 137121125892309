// @flow

import React, { useContext } from 'react';
import styled from 'styled-components';

import Form from './Form';
import { H1 } from '../../shared/ui/Typography';
import OnboarderTable from './OnboarderTable';

import { CurrentUserContext } from '../app/Main';

const H1Caps = styled(H1)`
  text-transform: capitalize;
`;

// Todo:  force to login if no user
export default function UserAdmin() {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div>
      <H1Caps>Welcome, Gangplank Captain {currentUser ? currentUser.name : ''}</H1Caps>
      <Form />
      <OnboarderTable />
    </div>
  );
}
