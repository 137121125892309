import gql from 'graphql-tag';

import { cache } from '../../apollo';
import { FetchCustomers } from '../customers/List';
import validations from '../../modules/models/Validations';

export const EMAIL_REPORT_TYPES = {
  '1': 'Horizon Report',
  '2': 'Weekly Phishing Report',
};

export const validated = (email, subcribeToVal) => {
  const valErrors = [];
  if (!validations.presence(email)) valErrors.push({ email: 'Email cannot be blank' });
  if (!validations.email(email) && validations.presence(email)) {
    valErrors.push({ email: 'Email is not a valid format' });
  }
  if (!subcribeToVal) valErrors.push({ select: 'Please select a report' });

  return valErrors;
};

export const EmailSubscriptionsQuery = gql`
  query EmailSubscriptions($customerId: String!) {
    emailSubscriptions(customerId: $customerId)
      @rest(path: "/onboarding/list_subscriptions/{args.customerId}", type: "EmailSubscribers") {
      customerId
      email
      id
      mailingIds
    }
  }
`;

export const getCustomerById = customerId => {
  const { customers } = cache.readQuery({ query: FetchCustomers });
  return customers.find(customer => customer.customerId === customerId);
};

export const SubscribeToReportMutation = gql`
  mutation SubscribeToReport($input: AddSubscriptionInput!) {
    addSubscriptions(input: $input) @rest(method: "POST", path: "/onboarding/subscribe", type: "EmailSubscribers") {
      customerId
      email
      id
      mailingIds
    }
  }
`;

export const EditSubscriptionMutation = gql`
  mutation EditSubscription($input: EditSubscriptionInput!) {
    editSubscription(input: $input)
      @rest(method: "PUT", path: "/onboarding/edit_subscription", type: "EmailSubscribers") {
      customerId
      email
      id
      mailingIds
    }
  }
`;

export const UnsubscribeFromReportsMutation = gql`
  mutation UnsubscribeFromReports($input: SetUnSubscriptionInput!) {
    deleteSubscriptions(input: $input)
      @rest(method: "POST", path: "/onboarding/unsubscribe", type: "EmailSubscribers") {
      customerId
      email
      id
      mailingIds
    }
  }
`;

/* eslint-disable no-shadow */
// $FlowFixMe
export const subscriptionsUpdateOnAdd = (cache, { data: { addSubscriptions } }) => {
  cache.writeQuery({
    data: { emailSubscriptions: addSubscriptions },
    query: EmailSubscriptionsQuery,
  });
};
/* eslint-disable no-shadow */
