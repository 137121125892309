// @flow

import { rem } from 'polished';
// $FlowFixMe
import React, { useCallback, useEffect, useRef, useState, type Node } from 'react';
import styled, { css } from 'styled-components';

import Cell from '../../molecules/Table/Cell';

// $FlowFixMe
import { ReactComponent as CaretDownDoubleSVG } from './caret-down-double.svg';
import Options from './Options';
// $FlowFixMe
import { ReactComponent as TriangleDownSVG } from './triangle-down.svg';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Backdrop = styled.div`
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.colors.grayBlue};
  cursor: pointer;
  display: inline-flex;
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(12)};
  line-height: 1;
  margin: 0 ${({ theme, noMargin }) => (noMargin ? 0 : rem(theme.spacing.sm))};
  outline: 0;
  padding: 0 ${({ theme }) => rem(theme.spacing.md)};
  position: relative;
  text-align: left;
  width: auto;
  white-space: nowrap;

  ${Cell} > & {
    margin: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.ashGrey} !important;
      cursor: not-allowed;
    `};

  ${({ expanded }) =>
    expanded &&
    css`
      display: flex;
      flex-grow: 1;
      height: ${rem(38)};
    `};

  ${({ focused }) =>
    focused &&
    css`
      background-color: ${({ theme }) => theme.colors.azureishWhite};
      border-color: ${({ theme }) => theme.colors.azureishWhite};
      color: ${({ theme }) => theme.colors.dimGray};
      font-weight: 500;
    `};

  ${({ noBackground }) =>
    noBackground &&
    css`
      background-color: transparent !important;
      border: none !important;
    `};

  ${({ size }) =>
    size === 'large' &&
    css`
      color: ${({ theme }) => theme.colors.black};
      font-size: ${rem(20)};
    `};
`;

const DoubleCaret = styled(CaretDownDoubleSVG)`
  align-self: center;
  height: ${rem(14)};
  width: ${rem(14)};
  margin-left: ${({ theme }) => rem(theme.spacing.md)};
`;

const Triangle = styled(TriangleDownSVG)`
  align-self: center;
  height: ${rem(5)};
  margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  width: ${rem(6)};
`;

type OptionTypes = React$Node;

type SyntheticKeyboardEventElement<E> = {
  // eslint-disable-next-line react/no-unused-prop-types
  target: E,
} & SyntheticKeyboardEvent<EventTarget>;

type SyntheticMouseEventElement<E> = {
  target: E,
} & SyntheticMouseEvent<EventTarget>;

//
// Main component
// -------------------------------------------------------------------------------------------------

type Props = {
  children?: Node,
  dataTestId?: string,
  defaultSelected?: OptionTypes,
  disabled?: boolean,
  expanded?: boolean, // changes height to match text inputs
  icon?: string,
  noBackground?: boolean,
  noMargin?: boolean,
  options?: OptionTypes[],
  onOptionSelected?: Function,
  refreshDefault?: boolean, // Used to set default value every update if children is not defined
  renderAsOption?: Function,
  renderAsSelected?: Function,
  size?: string,
};

export default function Dropdown(props: Props) {
  const { disabled = false, expanded = true, noBackground = false, noMargin = false } = props;
  const { dataTestId = 'organisms-dropdown', options = [], size = 'small' } = props;
  const { renderAsOption = renderOptionPassthrough, renderAsSelected = renderSelectedPassthrough } = props;
  const { children, defaultSelected, icon, onOptionSelected, refreshDefault } = props;

  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = useCallback(
    (selected: OptionTypes) => {
      if (onOptionSelected) onOptionSelected(selected);
      setIsOpen(false);
    },
    [onOptionSelected]
  );

  useEffect(() => {
    if (defaultSelected && !children && refreshDefault) {
      handleOptionSelect(defaultSelected);
    }
  }, [defaultSelected, children, handleOptionSelect, refreshDefault]);

  useEffect(() => {
    function handleWindowClick({ target }: SyntheticMouseEventElement<HTMLElement>) {
      if (target !== containerRef.current && !isOption(target) && target !== null) {
        setIsOpen(false);
      }
    }

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, [containerRef, setIsOpen]);

  function handleClick({ target }: SyntheticMouseEventElement<HTMLElement>) {
    if (isOption(target)) return;

    if (!isOpen && containerRef.current) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }

  function handleKeyDown({ shiftKey, target, which }: SyntheticKeyboardEventElement<HTMLElement>) {
    const id = target.getAttribute('data-optionindex') || '';
    const split = id.split('-');
    if (which === 9) {
      if (shiftKey) {
        if (target === containerRef) setIsOpen(false);
      } else {
        const shouldClose =
          options && split.length === 2 && split[1] === 'option' && parseInt(split[0], 10) === options.length - 1;
        if (shouldClose) setIsOpen(false);
      }
    }
  }

  function handleTriangleClick(event: SyntheticKeyboardEventElement<HTMLElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  function renderOptionPassthrough(option): OptionTypes {
    return option;
  }

  function renderSelectedPassthrough(selected?: Node): ?Node {
    return selected;
  }

  return (
    <>
      {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
      <Container
        // @testing-library/react aka react-testing-library uses this
        data-testid={dataTestId}
        disabled={disabled}
        expanded={expanded}
        focused={isOpen}
        ref={containerRef}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        noBackground={noBackground}
        noMargin={noMargin}
        size={size}
        tabIndex={disabled ? undefined : 0}
      >
        {renderAsSelected(children)}
        {!icon && <Triangle onClick={handleTriangleClick} />}
        {icon && <DoubleCaret icon="caret" />}

        {isOpen && !disabled && (
          <Options handleSelect={handleOptionSelect} options={options} renderAsOption={renderAsOption} />
        )}
      </Container>
    </>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function isOption(target: HTMLElement) {
  const id = target.getAttribute('data-optionindex');
  if (id) {
    const split = id.split('-');
    return split && split.length === 2 && split[1] === 'option';
  }
  return false;
}
