// @flow

/**
 * Header Subcomponent for SideBarNav Component
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import uuidv1 from 'uuid/v1';
import styled from 'styled-components';

import arrow from './arrow.svg';

type Props = { children: Array<Object>, className: string, label: boolean, to: boolean };

function Header({ children, className, label, to }: Props) {
  const inner = [<b key={uuidv1()}>{children}</b>];
  if (label) inner.push(<i>{label}</i>);

  return (
    <h3 className={className}>
      {to ? (
        <NavLink to={to} activeClassName="active" exact>
          {inner}
        </NavLink>
      ) : (
        inner
      )}
    </h3>
  );
}

export default styled(Header)`
  /* stylelint-disable no-descending-specificity */
  &:not(:first-child) {
    margin-top: 40px;
  }

  &,
  & > a {
    color: inherit;
    display: flex;
    font-weight: normal;
    font-size: 16px;
    height: 40px;
    justify-content: space-between;
    line-height: 40px;
    text-decoration: none;
    text-transform: none;
    width: 100%;

    &.active {
      background: transparent url(${arrow}) center right no-repeat;
      background-size: 5px 10px;
      color: #44b9f8;
    }
  }

  b {
    font-weight: normal;
  }

  i {
    color: #888;
    font-size: 11px;
    font-style: normal;
  }
`;
