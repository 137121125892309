import { nanoid } from 'nanoid';

/**
 * Returns a tiny, secure, URL-friendly, unique string.
 */

// eslint-disable-next-line import/prefer-default-export
export function random(size?: number): string {
  return nanoid(size);
}
