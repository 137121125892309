// @flow

import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

// $FlowFixMe
import { ReactComponent as SmallAnimation } from './loading-alt.svg';
// $FlowFixMe
import { ReactComponent as Animation } from './loading.svg';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: ${({ theme }) => rem(theme.spacing.xl * 2)};

  ${({ small }) =>
    small &&
    css`
      align-items: left;
      display: inline-flex;
      min-height: 0;
      justify-content: left;
    `};

  svg {
    fill: ${({ theme }) => theme.colors.deepSkyBlue};
    stroke: ${({ theme }) => theme.colors.deepSkyBlue};

    ${({ dark }) =>
      dark &&
      css`
        fill: ${({ theme }) => theme.colors.independence};
        stroke: ${({ theme }) => theme.colors.independence};
      `};
  }
`;

type Props = {
  dark?: boolean,
  dataTestId?: string,
  small?: boolean,
};

export default function Loading({ dark, dataTestId = 'atom-loading', small }: Props) {
  return (
    <Container dark={dark} data-testid={dataTestId} small={small}>
      {small ? <SmallAnimation /> : <Animation />}
    </Container>
  );
}
