// @flow

import { loader } from 'graphql.macro';
import { Query, Mutation } from 'react-apollo';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, SubmitButton } from '../../shared/ui/Buttons';
import { Body, Cell, Head, Header, Row, Container as Table } from '../../shared/ui/molecules/Table';
import DeleteButton from './DeleteButton';
import { StyledButtonDiv, StyledCell } from '../customers/List';
import { StyledTextInput } from '../../shared/ui/Search';

const EditUser = loader('./queries/EditUserMutation.graphql');
const GetOnboarders = loader('./queries/GetOnboardersQuery.graphql');

const P = styled.p`
  display: flex;
  justify-content: center;
`;

const SmlButton = styled(Button)`
  min-width: 65px;
  padding: 0 5px;
`;

const SmlSubmitButton = styled(SubmitButton)`
  min-width: 55px;
  padding: 0 5px;
`;

export default function OnboarderTable() {
  const [editing, setEditing] = useState(false);
  const [editingEmail, setEmail] = useState('');
  const [editingId, setId] = useState(null);
  const [editingName, setName] = useState('');

  const setInit = () => {
    setEditing(false);
    setEmail('');
    setId(null);
    setName('');
  };

  const handleEditClick = (e, onboarder) => {
    const { email, id, name } = onboarder;
    e.preventDefault();
    setEditing(true);
    setEmail(email);
    setId(id);
    setName(name);
  };

  const handleCancel = e => {
    e.preventDefault();
    setInit();
  };

  const headings = ['Name', 'Email', ''];
  const renderHeadings = () => headings.map(heading => <Header key={heading}>{heading}</Header>);

  const renderField = (currentValue, id, setFunction, editedValue) =>
    editing && id === editingId ? (
      <StyledTextInput type="text" value={editedValue} onChange={e => setFunction(e.target.value)} />
    ) : (
      <StyledCell>{currentValue}</StyledCell>
    );

  const renderEditDeleteButtons = onboarder => {
    const { id } = onboarder;
    return (
      <>
        <Mutation mutation={EditUser}>
          {(editUser, { error: editUserError, loading: editLoading }) => {
            if (editUserError) return <P>error</P>;
            if (editLoading) return <P>Editing...</P>;

            // update user
            const handleEditSave = async e => {
              e.preventDefault();
              const input = { email: editingEmail, name: editingName };
              await editUser({ variables: { id: editingId, input } });
              if (!editLoading && !editUserError) setInit();
            };
            return (
              <StyledButtonDiv>
                <SmlSubmitButton
                  onClick={editing && id === editingId ? handleEditSave : e => handleEditClick(e, onboarder)}
                >
                  {editing && id === editingId ? 'Save' : 'Edit'}
                </SmlSubmitButton>
                {editing && id === editingId ? (
                  <SmlButton onClick={handleCancel}>Cancel</SmlButton>
                ) : (
                  <DeleteButton id={id} />
                )}
              </StyledButtonDiv>
            );
          }}
        </Mutation>
      </>
    );
  };

  return (
    <Table dimmed spaced verticalAlign="middle" zebraStripes>
      <Head>
        <Row>{renderHeadings()}</Row>
      </Head>
      <Body>
        <Query query={GetOnboarders}>
          {({ data, error, loading }) => {
            if (loading)
              return (
                <Row>
                  <Cell colSpan="3">
                    <p>Loading...</p>
                  </Cell>
                </Row>
              );
            if (error)
              return (
                <Row>
                  <Cell colSpan="3">
                    <p>Error</p>
                  </Cell>
                </Row>
              );

            const { onboarders = [] } = data;
            onboarders.sort((a, b) =>
              // eslint-disable-next-line no-nested-ternary
              a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
            );

            const renderOnboarderRow = onboarder => {
              const { email, id, name } = onboarder;
              const nameCap = name.charAt(0).toUpperCase() + name.slice(1);
              return (
                <Row key={id}>
                  <Cell>{renderField(nameCap, id, setName, editingName)}</Cell>
                  <Cell>{renderField(email, id, setEmail, editingEmail)}</Cell>
                  <Cell>{renderEditDeleteButtons(onboarder)}</Cell>
                </Row>
              );
            };

            return <>{onboarders.map(onboarder => renderOnboarderRow(onboarder))}</>;
          }}
        </Query>
      </Body>
    </Table>
  );
}
