// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const ErrorMessage = styled.div.attrs({ 'data-testid': 'molecule-error-message' })`
  background-color: ${({ theme }) => theme.colors.pastelRed};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(2)};
  font-size: ${rem(14)};
  font-weight: 500;
  padding: ${({ theme }) => rem(theme.spacing.md)};
`;

export default ErrorMessage;
