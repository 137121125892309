// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import { TextInput } from '../Forms';

export const StyledTextInput = styled(TextInput)`
  float: left;
  font-size: 14px;
  height: 30px;
  line-height: 20px;
  margin: 20px 25px 20px 5px;
  width: 250px;
`;

type Props = { onChange: Function, searchByPlaceholder: string };

export default function SearchInput({ onChange, searchByPlaceholder }: Props) {
  const [value, setValue] = useState('');

  const handleInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-shadow
    const { value } = e.target;
    setValue(value);
    if (value.length > -1) {
      onChange(value);
    }
  };

  return (
    <form>
      <StyledTextInput onChange={handleInputChange} value={value} placeholder={`Search By ${searchByPlaceholder}`} />
    </form>
  );
}
