// @flow

import hash from 'hash-sum';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import ConditionalRender from '../../../shared/ui/ConditionalRender';
import Loading from '../../../shared/ui/Loading';

import PaddedContent from '../../../shared/ui/atoms/PaddedContent';
import Subtitle from '../../../shared/ui/atoms/Subtitle';

import AlertDialog from '../../../shared/ui/molecules/AlertDialog';

import Dropdown from '../../../shared/ui/organisms/Dropdown';

import { FetchCustomers } from '../List';

interface CustomerType {
  customerId: string;
  customerName: string;
  parentId: string;
}

interface Props {
  alertOpen: boolean;
  customer: CustomerType;
  handleConfirm: Function;
  loading: boolean;
  onActionCancel: Function;
}

export default function ParentReassignmentDialog({
  alertOpen,
  customer,
  handleConfirm,
  loading,
  onActionCancel,
}: Props) {
  const [selected, setSelected] = useState(null);

  const { data, loading: customersLoading } = useQuery(FetchCustomers);

  const customers = get(data, 'customers', []);
  const parents = customers.filter(c => c.accountType === 'Parent').filter(p => p.customerId !== customer.customerId);
  const parentNames = parents.map(p => p.customerName);

  const hashedParents = hash(parents);

  useEffect(() => {
    if (customers.length && !!customer) {
      const parentCustomer = parents.find(p => p.customerId === customer.parentId);
      if (parentCustomer) setSelected(parentCustomer.customerName);
    }
    // including parents causes this to run on a new parent selection overwriting the user's choice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, hashedParents, setSelected]);

  const handleSetNewParent = () => {
    const parent = parents.find(p => p.customerName === selected);
    if (parent) {
      handleConfirm(parent.customerId, customer.customerId);
    }
  };

  return (
    <AlertDialog
      busy={loading || customersLoading}
      buttonText={<span>Confirm New Parent</span>}
      expanded
      message={
        <>
          <Subtitle>Select New Parent</Subtitle>
          <PaddedContent pushTop="md" style={{ textAlign: 'left' }}>
            <ConditionalRender condition={!!parentNames.length && !customersLoading} fallback={<Loading />}>
              <Dropdown
                dataTestId="new-parent-dropdown"
                onOptionSelected={parent => setSelected(parent)}
                options={parentNames}
              >
                {selected}
              </Dropdown>
            </ConditionalRender>
          </PaddedContent>
        </>
      }
      open={alertOpen}
      onActionCancel={onActionCancel}
      onActionConfirm={handleSetNewParent}
      wide
    />
  );
}
